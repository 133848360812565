import React, { Component } from 'react';
import { MyPagination } from "../Pagination";
import { MeasurementCard } from "./MeasurementCard";
import { AddMeasurement } from "./AddMeasurement";
import { Loading } from '../../Loading/Loading';


export class Measurements extends Component {
    displayName = Measurements.name
    countUrl = "api/panel/get-measurements-count"
    getUrl = "api/panel/get-measurements"// + /{from}/{count}

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            from: 0,
            perPage: 50,
            selectedPage: 1,
            measurements: [],
        }
    }

    componentDidMount() {
        this.fetchData(this.state.from);
    }

    handlePageChange = (number) => {
        this.setState({ isLoading: true, selectedPage: number });

        const newPage = parseInt(number);
        const newFrom = (newPage - 1) * this.state.perPage

        this.fetchData(newFrom);
    }

    fetchData = (from) => {

        if (!from) {
            from = this.state.from
        }
        this.setState({ isLoading: true }, () => {
            fetch(this.getUrl + "/" + from + "/" + this.state.perPage, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.JWT
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ measurements: data, isLoading: false });
                });
        });
    }

    render() {

        if (this.state.isLoading) {
            return (<Loading />);
        }

        const updateTime = performance.now();
        const items = this.state.measurements.map(m => <MeasurementCard {...this.props} key={m.id + "_" + updateTime} measurement={m} reloadData={this.fetchData}/>)

        return (
            <div>

                <h3 className="centering mb-3">Wymiary</h3>

                <AddMeasurement reloadData={this.fetchData} />


                <div className="py-3">
                    {items}
                </div>


                <MyPagination countUrl={this.countUrl} active={this.state.selectedPage} handlePageChange={this.handlePageChange} perPage={this.state.perPage} />

            </div>
        );
    }
}

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class Rating extends Component {
    displayName = Rating.name;
    constructor(props) {
        super(props);

        this.state = {

            apperanceScore: this.props.apperanceRating,
            mobilityScore: this.props.mobilityRating,
            defenceScore: this.props.defenceRating,
        }
    };

    getRating(score, id) {

        let inner = [];
        for (var i = 0; i < 5; i++) {
            if (i < score) {
                inner.push(<span key={`id${i}`} className="fa fa-star star-checked"></span>);
            }
            else {
                inner.push(<span key={`id${i}`} className="fa fa-star"></span>);
            }
        }
        return (
            <span className="ml-2">
                {inner}
            </span>);
    }

    render() {
        const apperanceRating = this.getRating(this.state.apperanceScore, "ap");
        const mobilityRating = this.getRating(this.state.mobilityScore, "mo");
        const defenceRating = this.getRating(this.state.defenceScore, "df");

        return (
            <table className="my-2 center">
                <tbody>
                    <tr>
                        <td><FormattedMessage id="rating.apperance"/>: </td>
                        <td className="rating">{apperanceRating}</td>
                    </tr>
                    <tr>
                        <td><FormattedMessage id="rating.mobility" />:</td>
                        <td className="rating">{mobilityRating}</td>
                    </tr>
                    <tr>
                        <td><FormattedMessage id="rating.defence" />:</td>
                        <td>{defenceRating}</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

//<Row className="mt-3 justify-content-start d-inline">
//    <Col xs="auto">
//        <Row><Col>Apperance:</Col></Row>
//        <Row><Col>Mobility:</Col></Row>
//        <Row><Col>Defence:</Col></Row>
//    </Col>
//    <Col xs="auto">
//        <Row><Col className="rating">{apperanceRating}</Col></Row>
//        <Row><Col className="rating">{mobilityRating}</Col></Row>
//        <Row><Col className="rating">{defenceRating}</Col></Row>
//    </Col >
//</Row >
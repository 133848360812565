import React, { Component } from 'react';
import { Loading } from "../Loading/Loading";
import { Cart } from "./Cart";
import { CartMeasurements } from './CartMeasurements';
import { CartShipping } from './CartShipping';
import { CartSummary } from './CartSummary';

export class CartBase extends Component {
    displayName = CartBase.name

    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            measurements: [],
            items: [],
            shippingData: {},
            comment:"",
            totalPrice: 0,
            estimatedTime: ""
        }


    }

    componentDidMount() {
    }

    onBackButton = () => {
        this.setState((prevState) => {
            return { step: prevState.step > 0 ? prevState.step - 1 : 0 }
        });
    }

    onNextButton = () => {
        this.setState((prevState) => {
            return { step: prevState.step > 3 ? 3 : prevState.step + 1 }
        });
    }

    onUpdateMeasurements = (meas) => {
        this.setState({ measurements: meas });
    }

    onUpdateItems = (itms, price) => {
        this.setState({ items: itms, totalPrice:price });
    }
    onUpdateShipping = (shipping) => {
        this.setState({ shippingData: shipping });
    }

    render() {
        if (this.state.Loading) {
            return (<Loading />);
        }

        let InnerComponent = this.state.step;
        switch (this.state.step) {
            case 0:
                InnerComponent = Cart;
                break;
            case 1:
                InnerComponent = CartMeasurements;
                break;
            case 2:
                InnerComponent = CartShipping;
                break;
            case 3:
                InnerComponent = CartSummary;
                break;
            default:
                InnerComponent = <h1>Shopping Cart</h1>;
        }
        let orderData = {
            measurements: this.state.measurements,
            shipping: this.state.shippingData,
            comment: this.state.comment
        }

        return (
            <div>
                <InnerComponent
                    onNextButton={this.onNextButton}
                    onBackButton={this.onBackButton}
                    onUpdateItems={this.onUpdateItems}
                    onUpdateMeasurements={this.onUpdateMeasurements}
                    onUpdateShipping={this.onUpdateShipping}
                    orderData={orderData}
                    shippingData={this.state.shippingData}
                    measurementsData={this.state.measurements}
                    updateCartBadge={this.props.updateCartBadge}
                    handleTabChange={this.props.handleTabChange}
                    {...this.props}
                />
            </div>
        );
    }
}

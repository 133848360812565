import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Loading } from '../Loading/Loading';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import { FormattedMessage } from 'react-intl';


export class GalleryGrid extends Component {
    displayName = GalleryGrid.name

    getUrl = "api/gallery/get-gallery"

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pictures: [],
            currentImage: 0
        }
    }

    componentDidMount() {
        fetch(this.getUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(res => res.json())
            .then(images => {
                this.setState({
                    isLoading: false,
                    pictures: images
                })
            })
    }

    openLightbox = (event, obj) => {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render() {

        if (this.state.isLoading) {
            return (<Loading />)
        }


        let images = [];
        if (this.state.pictures.length > 0) {
            for (var i = 0; i < this.state.pictures.length; i++) {
                images.push({
                    src: `app-images/${this.state.pictures[i].name}`,
                    width: 1,
                    height: 1,
                })
            }
        }

        return (
            <div className="my-5 mx-5">
                <Gallery photos={images} onClick={this.openLightbox} />
                <Lightbox images={images}
                    onClose={this.closeLightbox}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                />
                {this.state.pictures.length < 1 &&
                    <h4 className="text-center"> <FormattedMessage id="gallery.empty" /></h4>
                }
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';

export class Config extends Component {
    displayName = Config.name
    getConfigUrl = "api/panel/get-config";
    postUrl = "api/panel/edit-config";

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,

            configId: 0,
            workHours: 0,
            orderDelay: 0,

            errorMessage: "",
            sucessMessage: ""
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        fetch(this.getConfigUrl, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    configId: data.id,
                    workHours: data.workHoursNumber,
                    orderDelay: data.orderDelay,
                    isLoading: false
                });
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, errorMessage: "", sucessMessage: "" });

        fetch(this.postUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT
            },
            body: JSON.stringify({
                ID: this.state.configId,
                WorkHoursNumber: this.state.workHours,
                OrderDelay: this.state.orderDelay
            })
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ errorMessage: data.error })
                }
                else {
                    this.setState({ sucessMessage: "Zapisano!" })
                    this.fetchData();
                }
            });
    }


    render() {
        return (
            <div>
                <h3 className="centering" >Ustawienia planowania zamówień</h3>

                <Form onSubmit={this.handleSubmit} className="my-5">

                    {this.state.errorMessage.length > 0 &&
                        <Row className="centering">
                            <Col sm="4">
                                <Alert variant="danger">
                                    {this.state.ErrorMessage}
                                </Alert>
                            </Col>
                        </Row>
                    }

                    {this.state.sucessMessage.length > 0 &&
                        <Row className="centering">
                            <Col sm="4">
                                <Alert variant="success">
                                    {this.state.sucessMessage}
                                </Alert>
                            </Col>
                        </Row>
                    }

                    <Form.Row className="centering">
                        <Col sm={3}>
                            <Form.Label>Średnia ilość godzin pracy dziennie:</Form.Label>
                            <Form.Control name="workHours" type="number" min="0" value={this.state.workHours} onChange={this.handleChange} />
                        </Col>
                    </Form.Row>

                    <Form.Row className="centering">
                        <Col sm={3}>
                            <Form.Label>Dodaj tyle godzin do każdego nowego zamówienia:</Form.Label>
                            <Form.Control name="orderDelay" type="number" min="0" value={this.state.orderDelay} onChange={this.handleChange} />
                        </Col>
                    </Form.Row>


                    <Form.Row>
                        <Button className="my-1 mx-1" variant="primary" type="submit">Zatwierdź</Button><br />
                    </Form.Row>

                </Form>
            </div>
        );
    }
}

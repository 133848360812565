import React, { Component } from 'react';
import { Col, Card, Button, Modal } from "react-bootstrap";
import { Rating } from './Rating';
import CartActions from "../Cart/CartActions";
import { FormattedMessage } from 'react-intl';


export class PartShopCard extends Component {
    displayName = PartShopCard.name;

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            data: this.props.part,
        }
    }

    onAddToCartClick = (event) => {
        let armorType = this.state.data.typeEN.toUpperCase();
        CartActions.AddToCart({
            type: armorType,
            id: this.state.data.id,
            count: 1
        });
        this.props.updateCartBadge();
        this.props.showGoToCartModal();
    }



    render() {
        let imageSrc;

        if (this.state.data.photos) {
            imageSrc = `app-images/${this.state.data.photos[0]}`;
        }

        return (
            <Col xl={3} lg={4} md={4} sm={6} className="my-3">

                <Card className="text-center" border="danger">
                    <a href={`details/${this.state.data.id}`}><Card.Img variant="top" src={imageSrc} /></a>
                    <Card.Body>
                        <a className="undecorated" href={`details/${this.state.data.id}`}>
                            <Card.Title className="my-2">{this.state.lang === "pl" ? this.state.data.namePL : this.state.data.nameEN}</Card.Title>
                        </a>
                        <Card.Text>{this.state.lang === "pl" ? this.state.data.shortDescPL : this.state.data.shortDescEN}</Card.Text>
                        <div className="centering">
                            <Rating apperanceRating={this.state.data.apperanceScore} mobilityRating={this.state.data.mobilityScore} defenceRating={this.state.data.defenceScore} />
                        </div>
                        <Card.Title>{this.state.data.price} €</Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        <Button className="m-1" variant="outline-danger" href={`/details/${this.state.data.id}`}><FormattedMessage id="button.details"/></Button>
                        <Button className="m-1" variant="danger" onClick={this.onAddToCartClick}><FormattedMessage id="button.addToCart" /></Button>
                    </Card.Footer>
                </Card>
            </Col>
        );
    }
}

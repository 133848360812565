import React, { Component } from 'react';
import { Loading } from '../../Loading/Loading';
import { MyPagination } from '../Pagination';
import { OrderCard } from './OrderCard';

export class OrdersList extends Component {
    displayName = OrdersList.name
    getCountUrl = "api/panel/get-orders-count";// +/{status}
    getUrl = "api/panel/get-orders"// + /{status}/{from}/{count}

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            from: 0,
            perPage: 20,
            selectedPage: 1,

            orders: [],

            errorMsg: "",
            successMsg: ""
        };
    }

    componentDidMount() {
        this.fetchData(this.state.from);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.status !== this.props.status) {
            this.fetchData(this.state.from);
        }
    } 

    handlePageChange = (number) => {
        this.setState({ isLoading: true, selectedPage: number });

        const newPage = parseInt(number);
        const newFrom = (newPage - 1) * this.state.perPage

        this.fetchData(newFrom);
    }

    fetchData = (from) => {
        if (!from) {
            from = this.state.from
        }

        this.setState({ isLoading: true }, () => {
            fetch(this.getUrl + "/" + this.props.status + "/" + from + "/" + this.state.perPage, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.JWT
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, isLoading: false });
                });
        });
    }


    render() {

        if (this.state.isLoading) {
            return (<Loading />);
        }

        const updateTime = performance.now();
        const orderCards = this.state.orders.map(o => <OrderCard key={o.id + "_" + updateTime} order={o} reloadData={this.fetchData} />)

        return (
            <div>
                <div className="my-3">
                    {orderCards}
                </div>


                <MyPagination countUrl={this.getCountUrl + "/" + this.props.status} active={this.state.selectedPage} handlePageChange={this.handlePageChange} perPage={this.state.perPage} />
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

export class PanelNav extends Component {
    displayName = PanelNav.name
    constructor(props, context) {
        super(props, context);
        this.state = {
            key: props.tab,
        };
    }

    render() {
        return (
            <Tabs
                id="controlled-tab"
                className="mb-3 centering"
                activeKey={this.props.tab}
                onSelect={this.props.handleTabChange}
                >
                <Tab eventKey="Orders" title="Zamówienia">
                </Tab>
                <Tab eventKey="Config" title="Ustawienia">
                </Tab>
                <Tab eventKey="Sets" title="Sety">
                </Tab>
                <Tab eventKey="Parts" title="Części">
                </Tab>
                <Tab eventKey="Measurements" title="Wymiary">
                </Tab>
                <Tab eventKey="Gallery" title="Galeria">
                </Tab>
                <Tab eventKey="StockItems" title="Stock">
                </Tab>
            </Tabs>
        );
    }
}

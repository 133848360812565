import React, { Component } from 'react';
import { Row, Col, Card, Collapse, Badge } from "react-bootstrap";
import icons from 'glyphicons';
import { StockItemForm } from './StockItemForm';
import { DeleteStockItem } from './DeleteStockItem';
import { StockItemGroup } from './StockItemGroup';

export class StockItemCard extends Component {
    displayName = StockItemCard.name;

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.stockItem,
            isOpen: false,
            modalShow: false,
            deleteModalShow: false,

            pictures: [],
        }
    }

    onClick = (event) => {
        const isOpen = this.state.isOpen;
        this.setState({ isOpen: !isOpen });
    }

    onHide = (event) => {
        this.setState({ modalShow: false });
    }

    onShow = (event) => {
        this.setState({ modalShow: true });
    }

    onDeleteHide = (event) => {
        this.setState({ deleteModalShow: false });
    }

    onDeleteShow = (event) => {
        this.setState({ deleteModalShow: true });
    }

    render() {

        const editData = {
            id: this.state.data.id,
            namePL: this.state.data.namePL,
            nameEN: this.state.data.nameEN,
            descPL: this.state.data.descPL,
            descEN: this.state.data.descEN,
            shortDescPL: this.state.data.shortDescPL,
            shortDescEN: this.state.data.shortDescEN,
            hoursToMake: this.state.data.hoursToMake,
            price: this.state.data.price,
            typePL: this.state.data.typePL,
            isAvailable: this.state.data.isAvailable,
            mobilityScore: this.state.data.mobilityScore,
            defenceScore: this.state.data.defenceScore,
            apperanceScore: this.state.data.apperanceScore,
            stockItemGroups: this.state.data.stockItemGroups
        }

        let icon;

        if (this.state.isOpen) {
            icon = icons.arrowTriU;
        }
        else {
            icon = icons.arrowTriD;
        }


        let available = (
            <div>
                <hr />
                <Row>
                    <Col>
                        <h6>Dostępny w sklepie:</h6>
                        {this.state.data.isAvailable === true ? <Badge variant="success">TAK</Badge> : <Badge variant="danger">NIE</Badge>}
                    </Col>
                </Row>
            </div>
        )

        let images;
        if (this.state.data.photos != null && this.state.data.photos.length > 0) {
            const inner = this.state.data.photos.map(p => <a key={p} href={`app-images/${p}`} target="_blank" rel="noopener noreferrer"><img alt="" src={`app-images/${p}`} className="img-thumbnail mx-2" width="30%" /></a>);
            images = (
                <div>
                    <hr />
                    <Row>
                        <Col>
                            <h6>Zdjęcia:</h6>
                            {inner}
                        </Col>
                    </Row>
                </div>
            );
        }

        let measurementGroups = this.state.data.stockItemGroups.map(sig => <StockItemGroup key={sig.id} data={sig} onUpdateGroup={this.onUpdateGroup} onDeleteGroup={this.onDeleteGroup} editable={false} />)
        if (measurementGroups.length < 1) {
            measurementGroups = <h5 className="text-center">Dodaj dostępne wymiary!</h5>
        }


        return (
            <Row className="centering">

                <Col sm={8}>
                    <Card >
                        <Card.Header className="toggle-card-header" onClick={this.onClick}> {icon} {this.state.data.namePL} | {this.state.data.nameEN} {icon} </Card.Header>
                        <Card.Body>
                            <Collapse in={this.state.isOpen}>
                                <div>
                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            <h6>Opis polski:</h6>
                                            {this.state.data.descPL}
                                        </Col>
                                        <Col sm={6}>
                                            <h6>Opis angielski:</h6>
                                            {this.state.data.descEN}
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            <h6>Krótki opis polski:</h6>
                                            {this.state.data.shortDescPL}
                                        </Col>
                                        <Col sm={6}>
                                            <h6>Krótki opis angielski:</h6>
                                            {this.state.data.shortDescEN}
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mt-2">
                                        <Col>
                                            <h6>Typ części:</h6>
                                            {this.state.data.typePL}
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            <h6>Cena:</h6>
                                            {this.state.data.price} EUR
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col sm={4}>
                                            <h6>Mobility score:</h6>
                                            {this.state.data.mobilityScore} / 5
                                        </Col>
                                        <Col sm={4}>
                                            <h6>Defence score:</h6>
                                            {this.state.data.defenceScore} / 5
                                        </Col>
                                        <Col sm={4}>
                                            <h6>Apperance score:</h6>
                                            {this.state.data.apperanceScore} / 5
                                        </Col>
                                    </Row>

                                    <hr />
                                    <Row className="align-me centering">
                                        <Col sm={6}>
                                            <label>Wymiary</label>
                                        </Col>
                                        <Col sm={3}>
                                            <label>Sztuk w magazynie</label>
                                        </Col>
                                    </Row>
                                    {measurementGroups}

                                    {available}

                                    {images}

                                    <hr />

                                    <Row className="mb-2 ">
                                        <Col sm={{ offset: 10, span:2 }}>
                                            <StockItemForm data={editData} action="edit" reloadData={this.props.reloadData} />
                                            <DeleteStockItem setId={this.state.data.id} namePL={this.state.data.namePL} reloadData={this.props.reloadData} />
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        );
    }
}


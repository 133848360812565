import React, { Component } from 'react';
import { Form, Alert, Button, Row, Col, Modal } from "react-bootstrap";
import { Loading } from "../../Loading/Loading"

export class AddSet extends Component {
    displayName = AddSet.name
    getSetArmorPartsUrl = "api/panel/get-set-armorparts"// + /{setId}
    addSetUrl = "api/panel/add-set"


    constructor(props) {
        super(props);

        this.state = {

            namePL: "",
            nameEN: "",
            descPL: "",
            descEN: "",
            shortDescPL: "",
            shortDescEN: "",
            hoursToMake: 0,
            price: 0,
            armorParts: [],

            ErrorMessage: "",

            modalShow: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, modalShow: false, ErrorMessage: "" });
        const ids = this.state.armorParts.filter(a => a.required).map(a => a.id);


        fetch(this.addSetUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT

            },
            body: JSON.stringify({
                ID: this.state.id,
                NamePL: this.state.namePL,
                NameEN: this.state.nameEN,
                DescPL: this.state.descPL,
                DescEN: this.state.descEN,
                ShortDescPL: this.state.shortDescPL,
                ShortDescEN: this.state.shortDescEN,
                HoursToMake: this.state.hoursToMake,
                Price: this.state.price,
                ArmorPartsIds: ids
            })
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    this.setState({ modalShow: false })
                    this.props.reloadData();
                }
            });

    }

    handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === "checkbox") {
            const ap = this.state.armorParts.map(a => {
                if (parseInt(name) === a.id) {
                    a.required = checked;
                }
                return a;
            })
            this.setState({ armorParts: ap });
        }
        else {
            this.setState({ [name]: value });
        }
    }

    onCancel = (event) => {
        this.setState({ modalShow: false });
    }
    onShow = (event) => {
        fetch(this.getSetArmorPartsUrl + "/0", {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ armorParts: data, modalShow: true });
            });
    }

    render() {

        if (this.state.isLoading) {
            return (
                <Loading />
            );
        }

        const armorPartCheckboxes = this.state.armorParts.map(ap =>
            <Form.Check
                id={`checkbox${ap.id}`}
                key={ap.id}
                type="checkbox"
                name={ap.id}
                label={`${ap.partName}`}
                checked={ap.required}
                onChange={this.handleChange}
            />

        )

        return (
            <div>
                <Row className="centering">
                    <Col className="centering" sm={12}>
                        <Button className="mt-2" variant="primary" onClick={this.onShow}> Dodaj Nowy</Button>
                    </Col>
                </Row>



                <Modal show={this.state.modalShow} onHide={this.onCancel} size="lg" centered>
                    <Modal.Body>
                        <h5 className="centering">Dodaj Zbroje</h5>
                        <hr />

                        {this.state.ErrorMessage.length > 0 &&
                            <Row className="centering">
                                <Col sm="4">
                                    <Alert variant="danger">
                                        {this.state.ErrorMessage}
                                    </Alert>
                                </Col>
                            </Row>
                        }


                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Nazwa polska:</Form.Label>
                                    <Form.Control name="namePL" type="text" placeholder="Nazwa polska" value={this.state.namePL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Nazwa angielska:</Form.Label>
                                    <Form.Control name="nameEN" type="text" placeholder="Nazwa angielska" value={this.state.nameEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descPL" type="text" placeholder="Opis polski" value={this.state.descPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descEN" type="text" placeholder="Opis angielski" value={this.state.descEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescPL" type="text" placeholder="Krótki opis polski" value={this.state.shortDescPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescEN" type="text" placeholder="Krótki opis angielski" value={this.state.shortDescEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Czas produkcji (w godzinach):</Form.Label>
                                    <Form.Control name="hoursToMake" type="number" placeholder="Czas produkcji" value={this.state.hoursToMake} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Cena (EUR):</Form.Label>
                                    <Form.Control name="price" type="number" placeholder="Cena" value={this.state.price} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col>
                                    <Form.Label>Części zbroi:</Form.Label>
                                    {armorPartCheckboxes}
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Button className="my-1 mx-1" size="sm" variant="primary" type="submit">Dodaj</Button><br />
                                <Button className="my-1 mx-1" size="sm" onClick={this.onCancel} variant="outline-danger">Anuluj</Button>
                            </Form.Row>

                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

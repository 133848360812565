import React, { Component } from 'react';
import { Row, Col, Card, Collapse } from "react-bootstrap";
import icons from 'glyphicons';
import { FormattedMessage } from 'react-intl';
import { EditSet } from './EditSet';
import { DeleteSet } from './DeleteSet';

export class SetCard extends Component {
    displayName = SetCard.name
    getSetArmorPartsUrl = "api/panel/get-set-armorparts"// + /{setId}



    constructor(props) {
        super(props);

        this.state = {
            data: this.props.set,
            isOpen: false,
            modalShow: false,
            deleteModalShow: false,

            armorParts: []
        }
    }

    onClick = (event) => {
        const isOpen = this.state.isOpen;
        this.setState({ isOpen: !isOpen });

    }

    onHide = (event) => {
        this.setState({ modalShow: false });
    }

    onShow = (event) => {
        fetch(this.getSetArmorPartsUrl + "/" + this.state.data.id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ armorParts: data, modalShow: true });
            });
    }

    onDeleteHide = (event) => {
        this.setState({ deleteModalShow: false });
    }

    onDeleteShow = (event) => {
        this.setState({ deleteModalShow: true });
    }

    render() {


        const editData = {
            id: this.state.data.id,
            namePL: this.state.data.namePL,
            nameEN: this.state.data.nameEN,
            descPL: this.state.data.descPL,
            descEN: this.state.data.descEN,
            shortDescPL: this.state.data.shortDescPL,
            shortDescEN: this.state.data.shortDescEN,
            hoursToMake: this.state.data.hoursToMake,
            price: this.state.data.price,
            armorParts: this.state.data.armorParts
        }

        let icon;

        if (this.state.isOpen) {
            icon = icons.arrowTriU;
        }
        else {
            icon = icons.arrowTriD;
        }

        const armorParts = this.state.data.armorParts.map(ap => <li key={ap} >{ap}</li>)

        return (
            <Row className="centering">

                <Col sm={8}>
                    <Card >
                        <Card.Header className="toggle-card-header" onClick={this.onClick}> {icon} {this.state.data.namePL} | {this.state.data.nameEN} {icon} </Card.Header>
                        <Card.Body>
                            <Collapse in={this.state.isOpen}>
                                <div>
                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            {this.state.data.descPL}
                                        </Col>
                                        <Col sm={6}>
                                            {this.state.data.descEN}
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            {this.state.data.shortDescPL}
                                        </Col>
                                        <Col sm={6}>
                                            {this.state.data.shortDescEN}
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            Czas produkcji (godziny): {this.state.data.hoursToMake}
                                        </Col>
                                        <Col sm={6}>
                                            Cena (EUR): {this.state.data.price}
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mb-2">
                                        <Col sm={10}>
                                            <b><FormattedMessage id="armor.part" />:</b><br />
                                            <ul>
                                                {armorParts}
                                            </ul>
                                        </Col>
                                        <Col sm={2}>
                                            <EditSet data={editData} reloadData={this.props.reloadData} />
                                            <DeleteSet setId={this.state.data.id} namePL={this.state.data.namePL} reloadData={this.props.reloadData} />
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        );
    }
}

import React, { Component } from 'react';
import { Form, Button, Row, Col, Alert } from "react-bootstrap"
import { Loading } from "../../Loading/Loading"


export class EditMeasurementForm extends Component {
    displayName = EditMeasurementForm.name

    constructor(props) {
        super(props);

       // console.log(this.props)

        this.state = {
            data: this.props.data,
            ErrorMessage: "",
            isLoading: false
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, ErrorMessage: "" });
        const ids = this.state.data.armorParts.filter(a => a.required).map(a => a.id);
        //console.log(this.state.data)


        fetch('/api/panel/edit-measurement', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT
            },
            body: JSON.stringify({
                ID: this.state.data.id,
                NamePL: this.state.data.namePL,
                NameEN: this.state.data.nameEN,
                DescPL: this.state.data.descPL,
                DescEN: this.state.data.descEN,
                ArmorPartsIds: ids
            })
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    this.props.onCancel();
                    this.props.reloadData();
                }
            });

    }

    handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === "checkbox") {
            const data = { ...this.state.data }
            const ap = data.armorParts.map(a => {
                if (parseInt(name) === a.id) {
                    a.required = checked;
                }
                return a;
            })
            data.armorParts = ap;
            this.setState({ data: data });
        }
        else {
            const data = { ...this.state.data }
            data[name] = value
            this.setState({ data: data });
        }
    }

    render() {

        if (this.state.isLoading) {
            return (
                <Loading />
            );
        }

        const armorPartCheckboxes = this.state.data.armorParts.map(ap =>
            <Form.Check
                id={`checkbox${ap.id}`}
                key={ap.id}
                type="checkbox"
                name={ap.id}
                label={`${ap.partName}`}
                checked={ap.required}
                onChange={this.handleChange}
            />
        )

        return (
            <div>
                <h5 className="centering">Edycja {this.state.data.namePL}</h5>
                <hr />

                {this.state.ErrorMessage.length > 0 &&
                    <Row className="centering">
                        <Col sm="4">
                            <Alert variant="danger">
                                {this.state.ErrorMessage}
                            </Alert>
                        </Col>
                    </Row>
                }


                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Col sm={6}>
                            <Form.Label>Nazwa polska:</Form.Label>
                            <Form.Control name="namePL" type="text" placeholder="Nazwa polska" value={this.state.data.namePL} onChange={this.handleChange} />
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Nazwa angielska:</Form.Label>
                            <Form.Control name="nameEN" type="text" placeholder="Nazwa angielska" value={this.state.data.nameEN} onChange={this.handleChange} />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={6}>
                            <Form.Label>Opis polski:</Form.Label>
                            <Form.Control as="textarea" rows="3" name="descPL" type="text" placeholder="Opis polski" value={this.state.data.descPL} onChange={this.handleChange} />
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Opis angielski:</Form.Label>
                            <Form.Control as="textarea" rows="3" name="descEN" type="text" placeholder="Opis angielski" value={this.state.data.descEN} onChange={this.handleChange} />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col>
                            <Form.Label>Części zbroi:</Form.Label>
                            {armorPartCheckboxes}
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Button className="my-1 mx-1" size="sm" variant="primary" type="submit">Zatwierdź</Button><br />
                        <Button className="my-1 mx-1" size="sm" onClick={this.props.onCancel} variant="outline-danger">Anuluj</Button>
                    </Form.Row>

                </Form>
            </div>
        );
    }
}

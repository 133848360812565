import React, { Component } from 'react';
import { Row, Col, Alert, Button, Modal, Table, Form } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { Rating } from '../Shop/Rating';
import { Loading } from "../Loading/Loading";
import { FormattedMessage } from 'react-intl';
import CartActions from '../Cart/CartActions';




export class StockDetails extends Component {
    displayName = StockDetails.name;
    getStockItemUrl = "api/stock/get-stock-item/"; // {itemId}"

    constructor(props) {
        super(props);

        this.state = {

            lang: localStorage.lang,
            loading: true,
            errorMessage: "",
            data: {},
            selectedGroupId: 1,

            stockItemId: window.location.href.substr(window.location.href.lastIndexOf('/') + 1),
            modalShow: false,
            groupmodalShow: false,
        }
    };

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            fetch(this.getStockItemUrl + "/" + this.state.stockItemId)
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        this.setState({ errorMessage: data.error, isLoading: false });
                    }
                    else {
                        this.setState({
                            data: data, selectedGroupId: data.stockItemGroups[0].id, isLoading: false
                        });
                    }
                });
        });
    }

    getRating(score, id) {

        let inner = [];
        for (var i = 0; i < 5; i++) {
            if (i < score) {
                inner.push(<span key={`id${i}`} className="fa fa-star star-checked"></span>);
            }
            else {
                inner.push(<span key={`id${i}`} className="fa fa-star"></span>);
            }
        }
        return (
            <span className="ml-2">
                {inner}
            </span>);
    }
    onAddToCartClick = (event) => {
        if (document.querySelectorAll('input[type=radio]:checked').length < 1) {
            return;
        }

        let armorType = "STOCK";
        CartActions.AddToCart({
            type: armorType,
            id: this.state.data.id,
            count: 1,
            measuremetsId: this.state.selectedGroupId
        });
        this.onGroupModalHide();
        this.props.updateCartBadge();
        this.showGoToCartModal();
    }

    onGroupModalHide = (event) => {
        this.setState({ groupmodalShow: false });
    }
    onGroupModalShow = (event) => {
        this.setState({ groupmodalShow: true });
    }

    getMeasuremets = (joined) => {
        let measurements = [];
        let nameValues = joined.split("|");
        let langIndex = 0;
        if (localStorage.lang !== "pl") {
            langIndex = 1;
        }

        for (var i = 0; i < nameValues.length; i++) {
            let pair = nameValues[i].split(",");

            measurements.push({
                name: pair[0].split(";")[langIndex],
                value: pair[1],
            });

        }

        return measurements;
    }
    handleGroupClick = (event) => {
        this.setState({ selectedGroupId: +event.target.value });
    }

    onModalHide = (event) => {
        this.setState({ modalShow: false });
    }
    showGoToCartModal = (event) => {
        this.setState({ modalShow: true });
    }
    onGoToCart = (event) => {
        this.props.history.push("/cart");
    }

    render() {
        if (this.state.errorMessage.length > 0) {
            return (<Row className="centering">
                <Col sm={4} >
                    <Alert variant="danger">
                        {this.state.errorMessage}
                    </Alert>
                </Col>
            </Row>);
        }

        if (this.state.isLoading) {
            return (<Loading />);
        }

        let galleryImages;
        let gallery;
        if (this.state.data.photos != null && this.state.data.photos.length > 0) {
            galleryImages = this.state.data.photos.map(p => { return { original: `app-images/${p}`, thumbnail: `app-images/${p}` } });
            gallery = <ImageGallery showPlayButton={false} items={galleryImages} />
        }

        let inner;
       // console.log(this.state)
        if (this.state.data.stockItemGroups) {
            inner = this.state.data.stockItemGroups.map(gr =>
                <tr key={gr.id} onClick={() => document.getElementById(`radio-${gr.id}`).click()} className="group-select-row">
                    <td className="my-auto">
                        <Form.Check
                            custom
                            type="radio"
                            id={`radio-${gr.id}`}
                            label=""
                            name="groupRadio"
                            //className="align-me"
                            value={gr.id}
                            onClick={this.handleGroupClick}
                        />
                    </td>
                    <td>
                        {this.getMeasuremets(gr.bodyMeasurementsNames).map(m => <span key={m.name}>{`${m.name}:`} &nbsp;&nbsp;  {`${m.value} cm`} <br /></span>)}
                    </td>
                </tr >
            );
        }

        let sizes;
        //console.log(this.state)
        if (this.state.data.stockItemGroups) {
            sizes = this.state.data.stockItemGroups.map(gr =>
                <tr key={`size-${gr.id}`}>
                    <td>
                        {this.getMeasuremets(gr.bodyMeasurementsNames).map(m => <span key={m.name}>{`${m.name}:`} &nbsp;&nbsp;  {`${m.value} cm`} <br /></span>)}
                    </td>
                </tr >
            );
        }


        return (
            <div>
                <Modal show={this.state.modalShow} onHide={this.onModalHide} size="sm" centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="h.itemAdded" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" onClick={this.onModalHide} variant="outline-danger"><FormattedMessage id="h.continueShopping" /></Button>
                            </Col>
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onGoToCart}><FormattedMessage id="h.goToCart" /></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.groupmodalShow} onHide={this.onGroupModalHide} centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="h.chooseSize" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Table hover className="table-group">
                                    <tbody>
                                        {inner}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="centering">
                            <Button className="my-1 mx-1" size="sm" onClick={this.onGroupModalHide} variant="outline-danger"><FormattedMessage id="button.cancel" /></Button>
                            <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onAddToCartClick}><FormattedMessage id="button.addToCart" /></Button><br />
                        </Row>

                    </Modal.Body>
                </Modal>

                <Row className="centering mt-3 mx-auto part-details">
                    <Col sm="12" md="8">
                        {gallery}
                    </Col>

                    <Col md="4">
                        <Row className="align-me">
                            <Col>
                                <h1>{this.state.lang === "pl" ? this.state.data.namePL : this.state.data.nameEN}</h1>
                                <h2>{this.state.data.price} €</h2>
                            </Col>
                        </Row>

                        <Rating apperanceRating={this.state.data.apperanceScore} mobilityRating={this.state.data.mobilityScore} defenceRating={this.state.data.defenceScore} />

                        <Row className="mt-5">
                            <Col>
                                <Button variant="danger" onClick={this.onGroupModalShow}><FormattedMessage id="button.addToCart" /></Button>
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col>
                                <h6>{this.state.lang === "pl" ? this.state.data.shortDescPL : this.state.data.shortDescEN}</h6>
                            </Col>
                        </Row>

                    </Col>

                </Row>
                <Row className="centering my-5 mx-auto part-details">
                    <Col md={{ span: 6 }}>
                        {this.state.lang === "pl" ? this.state.data.descPL : this.state.data.descEN}
                    </Col>
                </Row>

                <Row className="centering my-5 mx-auto">
                    <Col md={{ span: 5 }}>
                        <h5><FormattedMessage id="stock.availableSizes" /></h5>

                        <Table size="sm" hover bordered striped className="text-center">
                            <tbody>
                                {sizes}
                            </tbody>
                        </Table>

                    </Col>
                </Row>

            </div>

        );
    }
}
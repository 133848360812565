import React, { Component } from 'react';
import { Loading } from "../Loading/Loading";
import CartActions from "./CartActions";
import { FormattedMessage } from 'react-intl';
import { CartItem } from './CartItem';
import { Button, Row, Col, Alert } from 'react-bootstrap';

export class Cart extends Component {
    displayName = Cart.name
    requestUrl = "api/shop/get-parts-cart"

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            step: 0,
            items: [],
            Loading: true,
            totalPrice: 0,
            Error: ""
        }


    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let cartItems = CartActions.GetCartItems();
        if (cartItems.length < 1) {
            this.setState({
                Loading: false,
                items: []
            });
            return;
        }

        this.setState({ Loading: true }, () => {
            fetch(this.requestUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cartItems)
            })
                .then(response => response.json())
                .then(data => {
                    let cItems = [];
                    let price = 0;
                    for (var i = 0; i < data.length; i++) {
                        let lsCitem = cartItems.filter(lsitem => lsitem.id === data[i].id)[0];
                        cItems.push({
                            id: data[i].id,
                            photo: data[i].photo,
                            namePL: data[i].namePL,
                            nameEN: data[i].nameEN,
                            price: data[i].price,
                            count: lsCitem.count,
                            type: lsCitem.type,
                            totalPrice: +lsCitem.count * +data[i].price,
                            stockCount: data[i].stockCount,
                        })
                        price = price + lsCitem.count * data[i].price;
                    }
                    this.setState({ items: cItems, Loading: false, totalPrice: price });
                });
        });
    }

    updateTotalPrice = (value) => {
        this.setState((prevState, props) => ({
            totalPrice: prevState.totalPrice + value
        }));
    }

    removeItem = (id, type, count) => {
        CartActions.RemoveFromCartSingle(id, type, count);
        this.props.updateCartBadge();

    }

    addItem = (id, type, count) => {
        CartActions.AddToCartSingle(id, type, count);
        this.props.updateCartBadge();
    }

    deleteItems = (id, type) => {
        CartActions.RemoveFromCartAll(id, type);
        this.props.updateCartBadge();
        this.fetchData();
    }

    onNextButton = (event) => {
        this.setState({ Error: "" });
        let items = CartActions.GetCartItems();
        this.props.onUpdateItems(items, this.state.totalPrice);
        this.props.onNextButton();
    }

    render() {
        let cartItems = this.state.items.map(item => <CartItem key={item.id} data={item} addItem={this.addItem} deleteItems={this.deleteItems} updateTotalPrice={this.updateTotalPrice} removeItem={this.removeItem} />)
        if (cartItems.length < 1) {
            cartItems = <h4 className="text-center my-5"><FormattedMessage id="cart.empty" /></h4>
        }


        if (this.state.Error.length > 0) {
            return (<Row className="centering">
                <Col sm={4} >
                    <Alert variant="danger">
                        {this.state.Error}
                    </Alert>
                </Col>
            </Row>);
        }

        if (this.state.Loading) {
            return (<Loading />);
        }

        let summary;
        if (this.state.items.length > 0) {
            summary = (
                <span>
                    <h3 className="centering my-3"><FormattedMessage id="h.totalPrice" />: {this.state.totalPrice} &euro;</h3>

                    <Row className="centering" >
                        <Col sm="4" className="centering">
                            <Button variant="red" onClick={this.onNextButton}>
                                <FormattedMessage id="b.nextstep" />
                            </Button>
                        </Col>
                    </Row>
                </span>
            )
        }

        return (
            <div>
                <h1 className="centering"><FormattedMessage id="h.cart" /></h1>
                <hr />
                {cartItems}

                {summary}

            </div>
        );
    }
}

import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Loading } from '../../Loading/Loading';

export class GalleryImage extends Component {
    displayName = GalleryImage.name

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="gallery-image mx-2">
                <div
                    onClick={() => this.props.removeImage(this.props.image.id)}
                    className='delete-button'
                >
                    <span className="fa-stack delete-stack">
                        <i className="fa fa-circle fa-stack-2x fa-inverse"></i>
                        <i className="fa fa-times-circle fa-stack-2x"></i>
                    </span>
                </div>
                <a href={`app-images/${this.props.image.name}`} target="_blank" rel="noopener noreferrer"><img className="img-thumbnail" src={`app-images/${this.props.image.name}`} alt='' /></a>
            </div>
        );
    }
}

export default class CartActions {

    static GetCart() {
        return JSON.parse(localStorage.getItem('cart'));
    }

    static SetCart(cart) {
        localStorage.setItem('cart', JSON.stringify(cart));
    }

    static GetCartItemsCount() {
        let cart = this.GetCart();
        if (cart) {
            let count = 0;
            for (var i = 0; i < cart.length; i++) {
                count = count + cart[i].count;
            }
            return count;
        }
        return 0;
    }

    static GetCartItems() {
        let cart = this.GetCart();
        if (cart) {
            return cart;
        }
        return [];
    }

    static AddToCart(item) {

        let cart = this.GetCart();
        if (!cart) {
            this.SetCart([])
        }

        cart = this.GetCart();

        if (cart) {
            if (item.type === "STOCK") {
                let foundItem = cart.find(i => i.id === item.id && i.type === "STOCK");
                if (foundItem && foundItem.type === "STOCK") {
                    return;
                    //let index = cart.indexOf(foundItem);
                    //let newItem = {
                    //    type: foundItem.type,
                    //    id: foundItem.id,
                    //    count: +foundItem.count + 1,
                    //    measurementsId: foundItem.measurementsId
                    //};
                    //cart[index] = newItem;
                }
                else if (!foundItem) {
                    cart.push(item);
                }
                this.SetCart(cart);
            }
            else {
                let foundItem = cart.find(i => i.id === item.id && i.type !== "STOCK");
                if (foundItem && foundItem.type === "GAUNTLETS") {
                    let index = cart.indexOf(foundItem);
                    let newItem = {
                        type: foundItem.type,
                        id: foundItem.id,
                        count: +foundItem.count + 1
                    };
                    cart[index] = newItem;
                }
                else if (!foundItem) {
                    cart.push(item);
                }
                this.SetCart(cart);
            }

        }
    }

    static AddToCartSingle(id, type, count) {
        let cart = this.GetCart();
        if (cart) {
            if (type === "STOCK") {
                let foundItem = cart.find(item => item.id === id && item.type === "STOCK");
                if (foundItem) {
                    let index = cart.indexOf(foundItem);
                    let newItem = {
                        type: foundItem.type,
                        id: foundItem.id,
                        count: +count,
                        measuremetsId: foundItem.measuremetsId
                    };

                    cart[index] = newItem;
                    this.SetCart(cart)
                }
            }
            else {
                let foundItem = cart.find(item => item.id === id && item.type !== "STOCK");

                if (foundItem) {
                    let index = cart.indexOf(foundItem);
                    let newItem = {
                        type: foundItem.type,
                        id: foundItem.id,
                        count: +count,
                    };

                    cart[index] = newItem;
                    this.SetCart(cart)
                }
            }

        }
    }

    static RemoveFromCartSingle(id, type, count) {
        let cart = this.GetCart();
        if (cart) {
            if (type === "STOCK") {
                let foundItem = cart.find(item => item.id === id && item.type === "STOCK");

                if (foundItem) {
                    let index = cart.indexOf(foundItem);
                    let newItem = {
                        type: foundItem.type,
                        id: foundItem.id,
                        count: +count,
                        measuremetsId: foundItem.measuremetsId
                    };

                    if (newItem.count < 1) {
                        cart.splice(index, 1);
                    }
                    else {
                        cart[index] = newItem;
                    }
                    this.SetCart(cart)
                }
            }
            else {
                let foundItem = cart.find(item => item.id === id && item.type !== "STOCK");

                if (foundItem) {
                    let index = cart.indexOf(foundItem);
                    let newItem = {
                        type: foundItem.type,
                        id: foundItem.id,
                        count: +count
                    };

                    if (newItem.count < 1) {
                        cart.splice(index, 1);
                    }
                    else {
                        cart[index] = newItem;
                    }
                    this.SetCart(cart)
                }
            }

        }
    }

    static RemoveFromCartAll(id, type) {
        let cart = this.GetCart();
        if (cart) {
            if (type === "STOCK") {
                let foundItem = cart.find(item => item.id === id && item.type === "STOCK");
                let index = cart.indexOf(foundItem);

                if (foundItem) {
                    cart.splice(index, 1);
                    this.SetCart(cart);
                }
            }
            else {
                let foundItem = cart.find(item => item.id === id && item.type !== "STOCK");
                let index = cart.indexOf(foundItem);

                if (foundItem) {
                    cart.splice(index, 1);
                    this.SetCart(cart);
                }
            }


        }
    }

    static ClearCart() {
        this.SetCart([]);
    }
}
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import { Layout } from './components/Layout';

import { Login } from './components/Login/Login';
import { Panel } from './components/Admin/Panel';
import { NotFound } from './components/NotFound';

import { Shop } from './components/Shop/Shop';
import { PartDetails } from './components/Shop/PartDetails';

import CartActions from './components/Cart/CartActions';
import { CartBase } from './components/Cart/CartBase';
import { GalleryGrid } from './components/Gallery/GalleryGrid';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Stock } from './components/Stock/Stock';
import { StockDetails } from './components/Stock/StockDetails';
import { PrivacyPolicy } from './components/PrivacyPolicy';



export default class App extends Component {
    displayName = App.name

    constructor() {
        super();
        this.state = {
            cartItems: CartActions.GetCartItemsCount()
        };
    }

    updateCartBadge = () => {
        this.setState({ cartItems: CartActions.GetCartItemsCount() });
    }

    render() {
        return (
            <Layout cartItems={this.state.cartItems}>
                <Switch>
                    <Route exact path='/' render={(props) => <Shop {...props} updateCartBadge={this.updateCartBadge} />} />
                    <Route path='/shop' render={(props) => <Shop {...props} updateCartBadge={this.updateCartBadge} />} />
                    <Route exact path='/cart' render={(props) => <CartBase {...props} updateCartBadge={this.updateCartBadge} />} />
                    <Route path='/details' render={(props) => <PartDetails {...props} updateCartBadge={this.updateCartBadge} />} />
                    <Route exact path='/gallery' render={(props) => <GalleryGrid {...props} />} />
                    <Route exact path='/about' render={(props) => <About {...props} />} />
                    <Route exact path='/privacy-policy' render={(props) => <PrivacyPolicy {...props} />} />
                    <Route exact path='/contact' render={(props) => <Contact {...props} />} />
                    <Route exact path='/stock' render={(props) => <Stock {...props} updateCartBadge={this.updateCartBadge} />} />
                    <Route path='/stock-details' render={(props) => <StockDetails {...props} updateCartBadge={this.updateCartBadge} />} />
                    <Route exact path='/admin/login' component={Login} />
                    <Route exact path='/admin' render={(props) => <Panel {...props} inner="Orders" />}/>
                    <Route exact path='/admin/orders' render={(props) => <Panel {...props} inner="Orders" />} />
                    <Route exact path='/admin/measurements' render={(props) => <Panel {...props} inner="Measurements" />} />
                    <Route exact path='/admin/sets' render={(props) => <Panel {...props} inner="Sets" />} />
                    <Route exact path='/admin/parts' render={(props) => <Panel {...props} inner="Parts" />} />
                    <Route exact path='/admin/config' render={(props) => <Panel {...props} inner="Config" />} />
                    <Route exact path='/admin/gallery' render={(props) => <Panel {...props} inner="Gallery" />} />
                    <Route exact path='/admin/stockitems' render={(props) => <Panel {...props} inner="StockItems" />} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}

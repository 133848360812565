import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Loading } from '../../Loading/Loading';
import { GalleryImage } from './GalleryImage';

export class Gallery extends Component {
    displayName = Gallery.name

    getUrl = "api/gallery/get-gallery"
    uploadUrl = "api/panel/upload-gallery"
    deleteUrl = "api/panel/delete-gallery" // + /id

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pictures: []
        }
    }

    componentDidMount() {
        fetch(this.getUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT
            },
        })
            .then(res => res.json())
            .then(images => {
                this.setState({
                    isLoading: false,
                    pictures: images
                })
            })
    }

    onChange = e => {
        const files = Array.from(e.target.files)
        this.setState({ isLoading: true })

        const formData = new FormData()

        files.forEach((file, i) => {
            //console.log(file)
            formData.append("images",file);
        })

        fetch(this.uploadUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT
            },
            body: formData
        })
            .then(res => res.json())
            .then(images => {
                this.setState({
                    isLoading: false,
                    pictures: images
                })
            })
    }

    removeImage = id => {
        this.setState({
            pictures: this.state.pictures.filter(image => !image.name.includes(id))
        })
        fetch(`${this.deleteUrl}/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        });
    }

    render() {

        if (this.state.isLoading) {
            return(<Loading />)
        }


        let images = this.state.pictures.map(p => <GalleryImage image={p} key={p.id} removeImage={this.removeImage} />)


        return (
            <div>
                <h3 className="centering mb-4">Galeria</h3>

                <Row className="centering">
                    <Col className="centering" sm={12}>
                        <label htmlFor='multi'>
                            <div className="mt-2 btn btn-primary" variant="primary">
                                Dodaj Zdjęcia
                            </div>
                        </label>
                        <input hidden type='file' id='multi' multiple onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6>Zdjęcia:</h6>
                        {images}
                    </Col>
                </Row>
                

            </div>
        );
    }
}

import React, { Component } from 'react';
import { Row, Col, Card, Collapse, Badge } from "react-bootstrap";
import icons from 'glyphicons';
import { DeleteOrder } from './DeleteOrder';
import { UpdateStatusButton } from './UpdateStatusButton';

export class OrderCard extends Component {
    displayName = OrderCard.name;

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.order,
            isOpen: true,
            deleteModalShow: false,
        }
    }

    onClick = (event) => {
        const isOpen = this.state.isOpen;
        this.setState({ isOpen: !isOpen });

    }

    onDeleteHide = (event) => {
        this.setState({ deleteModalShow: false });
    }

    onDeleteShow = (event) => {
        this.setState({ deleteModalShow: true });
    }

    getDate = (dateString) => {
        return new Date(dateString).toISOString().substring(0, 10).replace(/T/, ' ');
    }
    getDateTime = (dateString) => {
        return new Date(dateString).toISOString().replace(/T/, ' ').replace(/\..+/, '');
    }

    render() {

        let icon;
        if (this.state.isOpen) {
            icon = icons.arrowTriU;
        }
        else {
            icon = icons.arrowTriD;
        }

        let armorParts = [];
        armorParts = this.state.data.armorParts.map(part => {
            if (part.length > 0) {
                return (<li key={part}>{`${part.split(",")[0]} x${part.split(",")[1]}`}</li>);
            }
        });
        let measurements = [];
        Object.keys(this.state.data.bodyMeasurements).map((key, value) => {
            measurements.push(<li key={key}>{key}: {this.state.data.bodyMeasurements[key]} cm</li>)
        });

        const statuses = this.state.data.statuses.map(s => <span key={s}><Badge pill variant="info" >{s}</Badge><br /></span>)


        let actions = [];
        if (!this.state.data.statuses.includes("Removed")) {
            if (this.state.data.statuses.includes("New")) {
                actions.push(<UpdateStatusButton key="acceptButton" orderId={this.state.data.id} status="Accepted" reloadData={this.props.reloadData} buttonText="Akceptuj" />);
            }
            if (this.state.data.statuses.includes("Accepted")) {
                if (!this.state.data.statuses.includes("MoneyRequested")) {
                    actions.push(<UpdateStatusButton key="requestMoneyButton" orderId={this.state.data.id} status="MoneyRequested" reloadData={this.props.reloadData} buttonText="Wysłano rachunek" />);
                }
                actions.push(<UpdateStatusButton key="receivedMoneyButton" orderId={this.state.data.id} status="MoneyReceived" reloadData={this.props.reloadData} buttonText="Opłacono" />);
            }
            if (this.state.data.statuses.includes("MoneyReceived")) {
                actions.push(<UpdateStatusButton key="readyButton" orderId={this.state.data.id} status="Ready" reloadData={this.props.reloadData} buttonText="Gotowe do wysyłki" />);
            }
            actions.push(<DeleteOrder key="removeButton" orderId={this.state.data.id} name={this.getDate(this.state.data.submitDate) + " " + this.state.data.firstName + " " + this.state.data.lastName + " " + this.state.data.price + "EUR"} reloadData={this.props.reloadData} />)
        }

        let stockItems = [];
        if (this.state.data.stockItemsJson) {
            let innerStock = [];
            let items = JSON.parse(this.state.data.stockItemsJson);
            for (var i = 0; i < items.length; i++) {
                items[i].measurements = JSON.parse(items[i].StockItemGroupJson).BodyMeasurementsNames;
                //console.log(items[i])
                let innerMeas = [];
                let meas = items[i].measurements.split("|");
                for (var j = 0; j < meas.length; j++) {
                    let splitted = meas[j].split(",");
                    innerMeas.push(<li>{`${splitted[0]}: ${splitted[1]}cm`}</li>)
                }

                innerStock.push(<li>{`${items[i].NamePL} x${items[i].Count}`}<ul>{innerMeas}</ul></li>)
            }



            stockItems = (
                <Col>
                    <b>Z magazynu:</b>
                    <ul>
                        {innerStock}
                    </ul>
                </Col>
            );
            //console.log(items)
        }


        return (
            <Row className="centering">

                <Col sm={12}>
                    <Card >
                        <Card.Header
                            className="toggle-card-header"
                            onClick={this.onClick}>
                            {icon} {this.getDate(this.state.data.submitDate)} | {this.state.data.firstName} {this.state.data.lastName} | {this.state.data.price} EUR {icon}
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={this.state.isOpen}>
                                <div className="m-3">

                                    <Row className="mt-2">

                                        <Col md xl={1} className="small-font">
                                            <p><b>Data dodania:</b> <br /> {this.getDate(this.state.data.submitDate)}</p>
                                            <p><b>Szacowane rozpoczęcie:</b> <br /> {this.getDate(this.state.data.estStartDate)}</p>
                                            <p><b>Szacowane ukończenie:</b> <br /> {this.getDate(this.state.data.estFinishDate)}</p>
                                        </Col>

                                        <Col md xl={7}>
                                            <Row>
                                                <Col md xl={5}>
                                                    <b>Części zbroi:</b>
                                                    {armorParts}
                                                </Col>

                                                <Col md xl={7}>
                                                    <b>Wymiary:</b>
                                                    {measurements}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                {stockItems}
                                            </Row>
                                        </Col>


                                        <Col md xl={3}>
                                            <p><b>Adres:</b> <br />
                                                {this.state.data.firstName} {this.state.data.lastName} <br />
                                                {this.state.data.email} <br />
                                                tel. {this.state.data.phoneNumber} <br />
                                                {this.state.data.street} {this.state.data.houseNumber} <br />
                                                {this.state.data.city} {this.state.data.postalCode} <br />
                                                {this.state.data.country}
                                            </p>
                                            <p>
                                                <b>Komentarz:</b> <br />
                                                {this.state.data.comment}
                                            </p>
                                        </Col>

                                        <Col md xl={1}>
                                            <p>
                                                <b>Cena:</b> <br />
                                                {this.state.data.price} EUR
                                            </p>
                                            <p>
                                                <b>Statusy:</b> <br />
                                                {statuses}
                                            </p>

                                            <b>Akcje:</b> <br />
                                            {actions}

                                        </Col>
                                    </Row>

                                </div>
                            </Collapse>
                        </Card.Body>
                    </Card>

                </Col >
            </Row >
        );
    }
}

import React, { Component } from 'react';
import { Loading } from "../Loading/Loading";
import CartActions from "./CartActions";
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col, Form, InputGroup, Alert, Modal } from 'react-bootstrap';

export class CartSummary extends Component {
    displayName = CartSummary.name
    requestUrl = "api/shop/get-parts-cart";
    estimatedTimeRequestUrl = "api/shop/get-estimated-date";
    submitOrderUrl = "api/shop/submit-order";

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            Loading: true,
            Error: "",
            validate: false,
            modalShow: false,
            posted: false,

            //order data
            shipping: this.props.orderData.shipping,
            measurements: this.props.orderData.measurements,
            comment: this.props.orderData.comment,
            items: [],
            totalPrice: 0,
            estimatedTime: "",
        }


    }
    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let cartItems = CartActions.GetCartItems();
        if (cartItems.length < 1) {
            this.setState({
                Loading: false,
                items: []
            });
            return;
        }

        this.setState({ Loading: true }, () => {
            fetch(this.requestUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cartItems)
            })
                .then(response => response.json())
                .then(data => {
                    let cItems = [];
                    let price = 0;
                    for (var i = 0; i < data.length; i++) {
                        let lsCitem = cartItems.filter(lsitem => lsitem.id === data[i].id)[0];
                        let measID = 0
                        if (data[i].measuremets) {
                            measID = data[i].measuremets.id;
                        }
                        
                        cItems.push({
                            id: data[i].id,
                            photo: data[i].photo,
                            namePL: data[i].namePL,
                            nameEN: data[i].nameEN,
                            price: data[i].price,
                            count: lsCitem.count,
                            type: lsCitem.type,
                            totalPrice: + lsCitem.count * +data[i].price,
                            measuremetsId: measID,
                        })
                        price = price + lsCitem.count * data[i].price;
                    }
                    this.setState({ items: cItems, Loading: false, totalPrice: price }, () => {
                        this.getEstimatedDate(cItems);
                    });

                });
        });
    }

    getEstimatedDate = (items) => {

        let ids = [];
        for (var i = 0; i < items.length; i++) {
            for (var j = 0; j < items[i].count; j++) {
                if (items[i].type !== "STOCK") {
                    ids.push(items[i].id);
                }
                
            }
        }

        this.setState({ Loading: true }, () => {
            fetch(this.estimatedTimeRequestUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ids)
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ Loading: false, estimatedTime: data.estimatedDate });
                });
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        this.setState({ validate: true });
        // -----test
        //this.onModalShow();
        //CartActions.ClearCart();
        //this.props.updateCartBadge();
        // ----
        if (form.checkValidity() === true) {
            this.submitOrder();
        }
    }

    submitOrder = () => {

        let shippingData = this.state.shipping;
        let items = this.state.items;
        let comment = this.state.comment;
        let estDate = this.state.estimatedTime;
        let measurements = this.state.measurements;
        let language = this.state.lang;

        let order = {
            shipping: shippingData,
            items: items,
            comment: comment,
            estimatedTime: estDate,
            measurements: measurements,
            lang: language
        };

        this.setState({ Loading: true, Error: "" }, () => {
            fetch(this.submitOrderUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(order)
            })
                .then(response => response.body.length > 0 ? response.json() : {})
                .then(data => {
                    if (data.errorPL && data.errorPL.length > 0) {
                        console.error(data.exception)
                        this.setState({ Loading: false, Error: this.state.lang === "pl" ? data.errorPL : data.errorEN });
                    }
                    else {
                        this.onModalShow();
                        CartActions.ClearCart();
                        this.props.updateCartBadge();
                    }
                });
        });
    }

    handleChange = event => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }


    onModalHide = (event) => {
        this.setState({ modalShow: false });
    }
    onModalShow = (event) => {
        this.setState({ Loading: false, modalShow: true, posted: true });
    }
    onGoToShop = event => {
        this.props.history.push("/shop");
    }

    render() {
        const validated = this.state.validate;

        if (this.state.Loading) {
            return (<Loading />);
        }

        let itemsRows = this.state.items.map(i =>
            <tr key={i.id}>
                <td>
                    <a className="undecorated" href={i.type === "STOCK" ? `/stock-details/${i.id}` : `details/${i.id}`} target="_blank"><h5>{this.state.lang === "pl" ? i.namePL : i.nameEN}</h5></a>
                </td>
                <td>
                    {`x${i.count}`}
                </td>
                <td>
                    {i.totalPrice} &euro;
                </td>
            </tr>
        );
        let items = (
            <table className="w-75">
                <tbody>
                    {itemsRows}
                </tbody>
            </table>
        );


        let measRows = this.state.measurements.map(i =>
            <tr key={i.id}>
                <td>
                    <b>{this.state.lang === "pl" ? i.namePL : i.nameEN}:</b>
                </td>
                <td>
                    {`${i.value} cm`}
                </td>
            </tr>
        );
        let measurements = (
            <table className="w-75">
                <tbody>
                    {measRows}
                </tbody>
            </table>
        );

        return (
            <div>

                <Modal show={this.state.modalShow} onHide={this.onModalHide} size="lg" centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="summary.modalHeader" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Alert variant="success" className="text-center"><FormattedMessage id="summary.modalText" /></Alert>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="centering">
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" onClick={this.onModalHide} variant="outline-danger"><FormattedMessage id="b.showSummary" /></Button>
                            </Col>
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onGoToShop}><FormattedMessage id="b.backToShop" /></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>


                <h1 className="centering"><FormattedMessage id="h.summaryHead" /></h1>
                <hr />

                {
                    this.state.Error.length > 0 &&
                    <Row className="centering">
                        <Col xs={12} sm={8} md={6} className="centering">
                            <Alert variant="danger" className="text-center">{this.state.Error}</Alert>
                            <hr />
                        </Col>
                    </Row>
                }

                <Row className="centering">
                    <Col xs="12" md="8" lg="6">
                        <h4 className="mt-2 text-center"><FormattedMessage id="summary.itemsLabel" /></h4>
                        <div className="centering">
                            {items}
                        </div>
                    </Col>
                </Row>

                {this.state.measurements.length > 0 &&
                    <Row className="centering">
                        <Col xs="12" md="8" lg="6">
                            <h4 className="mt-5 text-center"><FormattedMessage id="summary.measurementsLabel" /></h4>
                            <div className="centering">
                                {measurements}
                            </div>
                        </Col>
                    </Row>
                }

                <Row className="centering text-center">
                    <Col xs="12" md="8" lg="6">
                        <h4 className="mt-5"><FormattedMessage id="summary.shippingLabel" /></h4>
                        {`${this.state.shipping.fName} ${this.state.shipping.lName}`}<br />
                        {`${this.state.shipping.email}`}<br />
                        {`${this.state.lang === "pl" ? "Tel.:" : "Phone:"} ${this.state.shipping.phone}`}<br />
                        {`${this.state.shipping.street} ${this.state.shipping.apartement}`}<br />
                        {`${this.state.shipping.zipCode}, ${this.state.shipping.city} ${this.state.shipping.country}`}
                    </Col>
                </Row>

                <Row className="centering text-center">
                    <Col xs="12" md="8" lg="6">
                        <h6 className="mt-5"><FormattedMessage id="summary.estimatedTime" />:</h6>
                        {this.state.estimatedTime}
                    </Col>
                </Row>

                <Row className="centering text-center">
                    <Col xs="12" md="8" lg="6">
                        <h3 className="centering my-5"><FormattedMessage id="h.totalPrice" />: {this.state.totalPrice} &euro;</h3>
                        <hr />
                    </Col>
                </Row>

                {!this.state.posted &&
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <Form.Row className="centering text-center">
                            <Form.Group as={Col} xs="12" md="6" lg="4" controlId="comment">
                                <Form.Label><FormattedMessage id="summary.comment" /></Form.Label>
                                <Form.Control
                                    name="comment"
                                    value={this.state.comment}
                                    type="text"
                                    as="textarea"
                                    rows="3"
                                    maxLength="100000"
                                    placeholder={this.state.lang === "pl" ? "Wpisz je tutaj" : "Leave your comment here"}
                                    onChange={this.handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FormattedMessage id="e.invalidInput" />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <hr />

                        <Form.Row className="centering">
                            <Form.Group as={Col} xs="12" md="8" lg="6" className="centering">
                                <Button className="mx-2" variant="red-outline" onClick={this.props.onBackButton} ><FormattedMessage id="b.goback" /></Button>
                                <Button className="mx-2" variant="red" type="submit"><FormattedMessage id="b.submitOrder" /></Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                }

            </div>
        );
    }
}

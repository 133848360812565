import React, { Component } from 'react';
import { Row, Tabs, Tab, Modal, Button, Col, Alert } from 'react-bootstrap';
import { PartShopCard } from './PartShopCard';
import { MyPagination } from "../Admin/Pagination";
import { Loading } from "../Loading/Loading";
import { FormattedMessage } from 'react-intl';



export class Shop extends Component {
    displayName = Shop.name
    countUrl = "api/shop/get-parts-count"
    getUrl = "api/shop/get-parts"// + /{from}/{count}/{type}\
    getTypesUrl = `api/shop/get-part-types/`;

    all = localStorage.lang === "pl" ? "Wszystkie" : "All"

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            isLoading: true,
            from: 0,
            perPage: 24,
            selectedPage: 1,
            parts: [],

            partTypes: [],

            selectedType: window.location.href.substr(window.location.href.lastIndexOf('/') + 1) === "" ? this.all : window.location.href.substr(window.location.href.lastIndexOf('/') + 1),

            modalShow: false,
        }
    }

    componentDidMount() {
        fetch(this.getTypesUrl + localStorage.lang)
            .then(response => response.json())
            .then(data => {
                this.setState({ partTypes: data });
            });

        this.fetchData(this.state.from);
    }

    handleTabChange = (event) => {
        this.setState({ selectedType: event, selectedPage: 1 });
        this.fetchData(this.state.from);
        this.props.history.push("/shop/" + event);
    }

    handlePageChange = (number) => {
        this.setState({ isLoading: true, selectedPage: number });

        const newPage = parseInt(number);
        const newFrom = (newPage - 1) * this.state.perPage

        this.fetchData(newFrom);
    }

    fetchData = (from) => {

        if (!from) {
            from = this.state.from;
        }

        this.setState({ isLoading: true }, () => {
            let type = this.all;
            if (this.state.partTypes.includes(this.state.selectedType)) {
                type = this.state.selectedType;
            }

            fetch(this.getUrl + "/" + from + "/" + this.state.perPage + "/" + type)
                .then(response => response.json())
                .then(data => {
                    this.setState({ parts: data, isLoading: false });
                });
        });
    }

    generateTabs() {

        const inner = this.state.partTypes.map(pt => <Tab key={pt} eventKey={pt} title={pt}></Tab>)

        return (
            <Tabs
                id="controlled-tab-example"
                activeKey={this.state.partTypes.includes(this.state.selectedType) ? this.state.selectedType : this.all}
                onSelect={this.handleTabChange}
                className="mb-3 centering"
            >
                <Tab key={this.all} eventKey={this.all} title={this.all}></Tab>
                {inner}
            </Tabs>
        );
    }

    onModalHide = (event) => {
        this.setState({ modalShow: false });
    }
    showGoToCartModal = (event) => {
        this.setState({ modalShow: true });
    }
    onGoToCart = (event) => {
        this.props.history.push("/cart");
    }

    render() {
        if (this.state.isLoading) {
            return (<div>
                <h1 className="centering mb-3">GF Workshop</h1>
                <Row className="centering my-2">
                    <Col xs={12} sm={8} md={6} className="centering">
                        <Alert variant="info" className="text-center"><FormattedMessage id="shop.customArmor" /></Alert>
                    </Col>
                </Row>
                <Loading />
            </div>
            );
        }

        const updateTime = performance.now();

        const typeTabs = this.generateTabs();

        const partCards = this.state.parts.map(p => <PartShopCard {...this.props} showGoToCartModal={this.showGoToCartModal} key={p.id + "_" + updateTime} part={p} reloadData={this.fetchData} updateCartBadge={this.props.updateCartBadge} />)

        let pagination = <h5 className="centering my-3">No data available.</h5>;
        if (this.state.parts.length > 0) {
            pagination = <MyPagination countUrl={`${this.countUrl}/${this.state.selectedType}`} active={this.state.selectedPage} handlePageChange={this.handlePageChange} perPage={this.state.perPage} />;
        }

        return (
            <div>
                <Modal show={this.state.modalShow} onHide={this.onModalHide} size="sm" centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="h.itemAdded" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" onClick={this.onModalHide} variant="outline-danger"><FormattedMessage id="h.continueShopping" /></Button>
                            </Col>
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onGoToCart}><FormattedMessage id="h.goToCart" /></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <h1 className="centering mb-3">GF Workshop</h1>

                <Row className="centering my-2">
                    <Col xs={12} sm={8} md={6} className="centering">
                        <Alert variant="info" className="text-center"><FormattedMessage id="shop.customArmor" /></Alert>
                    </Col>
                </Row>

                {typeTabs}



                <Row className="centering mx-2">
                    {partCards}
                </Row>

                {pagination}

            </div>
        )
    }

}

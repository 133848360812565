import React, { Component } from 'react';
import { Row, Tabs, Tab, Modal, Button, Col, Alert } from 'react-bootstrap';
import { StockCard } from './StockCard';
import { MyPagination } from "../Admin/Pagination";
import { Loading } from "../Loading/Loading";
import { FormattedMessage } from 'react-intl';



export class Stock extends Component {
    displayName = Stock.name
    countUrl = "api/stock/get-stock-count"
    getUrl = "api/stock/get-stock-items"// + /{from}/{count}/

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            isLoading: true,
            from: 0,
            perPage: 24,
            selectedPage: 1,
            stockItems: [],

            modalShow: false,
        }
    }

    componentDidMount() {
      this.fetchData(this.state.from);
    }

    handlePageChange = (number) => {
        this.setState({ isLoading: true, selectedPage: number });

        const newPage = parseInt(number);
        const newFrom = (newPage - 1) * this.state.perPage

        this.fetchData(newFrom);
    }

    fetchData = (from) => {
        if (!from) {
            from = this.state.from;
        }

        this.setState({ isLoading: true }, () => {
            fetch(this.getUrl + "/" + from + "/" + this.state.perPage)
                .then(response => response.json())
                .then(data => {
                    this.setState({ stockItems: data, isLoading: false });
                });
        });
    }

    onModalHide = (event) => {
        this.setState({ modalShow: false });
    }
    showGoToCartModal = (event) => {
        this.setState({ modalShow: true });
    }
    onGoToCart = (event) => {
        this.props.history.push("/cart");
    }

    render() {
        if (this.state.isLoading) {
            return (<div>
                <h1 className="centering mb-3">GF Workshop</h1>
                <Row className="centering my-2">
                    <Col xs={12} sm={8} md={6} className="centering">
                        <Alert variant="info" className="text-center"><FormattedMessage id="shop.customArmor" /></Alert>
                    </Col>
                </Row>
                <Loading />
            </div>
            );
        }

        const updateTime = performance.now();
        const stockItemsCards = this.state.stockItems.map(p => <StockCard {...this.props} showGoToCartModal={this.showGoToCartModal} key={p.id + "_" + updateTime} stockItem={p} reloadData={this.fetchData} updateCartBadge={this.props.updateCartBadge} />)

        let pagination = <h5 className="centering my-3">No data available.</h5>;
        if (this.state.stockItems.length > 0) {
            pagination = <MyPagination countUrl={`${this.countUrl}`} active={this.state.selectedPage} handlePageChange={this.handlePageChange} perPage={this.state.perPage} />;
        }

        return (
            <div>
                <Modal show={this.state.modalShow} onHide={this.onModalHide} size="sm" centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="h.itemAdded" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" onClick={this.onModalHide} variant="outline-danger"><FormattedMessage id="h.continueShopping" /></Button>
                            </Col>
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onGoToCart}><FormattedMessage id="h.goToCart" /></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <h1 className="centering mb-3"><FormattedMessage id="stock.head" /></h1>

                <Row className="centering my-2">
                    <Col xs={12} sm={8} md={6} className="centering">
                        <Alert variant="info" className="text-center"><FormattedMessage id="shop.customArmor" /></Alert>
                    </Col>
                </Row>
                               
                <Row className="centering mx-2">
                    {stockItemsCards}
                </Row>

                {pagination}

            </div>
        )
    }

}

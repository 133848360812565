import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap';
import { Loading } from "../Loading/Loading";

export class MyPagination extends Component {
    displayName = MyPagination.name

    constructor(props) {
        super(props);


        this.state = {
            count: 0,
            isLoading: true,
            active: this.props.active ? this.props.active : 1,
            perPage: this.props.perPage
        }
    }

    componentDidMount() {
        fetch(this.props.countUrl, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ count: data.count, isLoading: false });
            });
    }
    componentWillReceiveProps(nextProps) {
        fetch(nextProps.countUrl, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ count: data.count, isLoading: false, active: data.active });
            });
    } 

    handleClick = (number) => (event) => {
        if (this.state.active === number) {
            return;
        }
        this.props.handlePageChange(number);
        this.setState({ active: number });
    }

    render() {
        if (this.state.isLoading) {
            return (<Loading />);
        }

        if (this.state.count === 0) {
            return (<h6 className=" my-5 centering" >Brak danych</h6>);
        }

        const pages = Math.ceil(this.state.count / this.state.perPage)

        let active = this.state.active;
        let items = [];


        items.push(<Pagination.First key="paginationFirst" onClick={this.handleClick(1)} />)
        items.push(<Pagination.Prev key="paginationPrev" onClick={this.handleClick(active - 1 > 0 ? active - 1 : 1)} />)
        if (active > 3) {
            items.push(<Pagination.Ellipsis key="paginationEllipsisLeft" />)
        }

        for (let number = active - 2; number <= active + 2; number++) {
            if (number > 0 && number <= pages) {
                items.push(
                    <Pagination.Item key={number} active={number === active} onClick={this.handleClick(number)}>
                        {number}
                    </Pagination.Item>
                );
            }
        }

        if (pages - active > 2) {
            items.push(<Pagination.Ellipsis key="paginationEllipsisRight" />)
        }
        items.push(<Pagination.Next key="paginationNext" onClick={this.handleClick(pages === active ? pages : active + 1)} />)
        items.push(<Pagination.Last key="paginationLast" onClick={this.handleClick(pages)} />)




        return (
            <div className="centering" >
                <Pagination>{items}</Pagination>
                <br />
            </div>
        );

    }
}

import React, { Component } from 'react';
import { Config } from "./Config/Config";
import { Measurements } from "./Measurements/Measurements";
import { Orders } from "./Orders/Orders";
import { Parts } from "./Parts/Parts";
import { Sets } from "./Sets/Sets";
import { PanelNav } from "./PanelNav";
import { Loading } from "../Loading/Loading";
import UserManager from "../Login/JWTAuth";
import { Gallery } from './Gallery/Gallery';
import { StockItems } from './StockItems/StockItems';

export class Panel extends Component {
    displayName = Panel.name

    constructor(props) {
        super(props);

        this.state = {
            rendering: this.props.inner,
            hasJWT: false
        }


    }

    componentDidMount() {
        if (UserManager.GetJWT() && !UserManager.IsJWTExpired()) {
            this.setState({ hasJWT: true });
        }
        else {
            UserManager.RemoveJWT();
            this.props.history.push("/admin/login");
        }
    }


    handleTabChange = (event) => {
        this.setState({ rendering: event });
        this.props.history.push("/admin/"+ event);
    }

    render() {
        if (!this.state.hasJWT) {
            return (<Loading />);
        }

        let InnerComponent = this.state.rendering;
        switch (this.state.rendering) {
            case "Config":
                InnerComponent = Config;
                break;
            case "Measurements":
                InnerComponent = Measurements;
                break;
            case "Orders":
                InnerComponent = Orders;
                break;
            case "Parts":
                InnerComponent = Parts;
                break;
            case "Sets":
                InnerComponent = Sets;
                break;
            case "Gallery":
                InnerComponent = Gallery;
                break;
            case "StockItems":
                InnerComponent = StockItems;
                break;
            default:
                InnerComponent = Orders;
        }

        return (
            <div>
                <PanelNav tab={this.state.rendering} handleTabChange={this.handleTabChange} />
                <InnerComponent  {...this.props} />
            </div>
        );
    }
}

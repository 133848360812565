import React, { Component } from 'react';
import { Button, Row, Col, Card, Collapse, Modal } from "react-bootstrap";
import icons from 'glyphicons';
import { FormattedMessage } from 'react-intl';
import { EditMeasurementForm } from './EditMeasurementForm';
import { DeleteMeasurement } from './DeleteMeasurement';

export class MeasurementCard extends Component {
    displayName = MeasurementCard.name
    getMeasurementArmorPartsUrl = "api/panel/get-measurement-armorparts"// + /{measurementId}



    constructor(props) {
        super(props);

        this.state = {
            data: this.props.measurement,
            isOpen: false,
            modalShow: false,
            deleteModalShow: false,

            armorParts: []
        }
    }

    onClick = (event) => {
        const isOpen = this.state.isOpen;
        this.setState({ isOpen: !isOpen });

    }

    onHide = (event) => {
        this.setState({ modalShow: false });
    }

    onShow = (event) => {
        fetch(this.getMeasurementArmorPartsUrl + "/" + this.state.data.id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ armorParts: data, modalShow: true });
            });
    }

    onDeleteHide = (event) => {
        this.setState({ deleteModalShow: false });
    }

    onDeleteShow = (event) => {
        this.setState({ deleteModalShow: true });
       // console.log(this.state)
    }

    render() {


        const editData = {
            id: this.state.data.id,
            namePL: this.state.data.namePL,
            nameEN: this.state.data.nameEN,
            descPL: this.state.data.descPL,
            descEN: this.state.data.descEN,
            armorParts: this.state.armorParts
        }

        let icon;

        if (this.state.isOpen) {
            icon = icons.arrowTriU;
        }
        else {
            icon = icons.arrowTriD;
        }

        const armorParts = this.state.data.armorParts.map(ap => <li key={ap}>{ap}</li>)

        return (
            <Row className="centering">

                <Modal show={this.state.modalShow} onHide={this.onHide} size="lg" centered>
                    <Modal.Body>
                        <EditMeasurementForm {...this.props} data={editData} onCancel={this.onHide} reloadData={this.props.reloadData} />
                    </Modal.Body>
                </Modal>


                <Col sm={8}>
                    <Card >
                        <Card.Header className="toggle-card-header" onClick={this.onClick}> {icon} {this.state.data.namePL} | {this.state.data.nameEN} {icon} </Card.Header>
                        <Card.Body>
                            <Collapse in={this.state.isOpen}>
                                <div>
                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            {this.state.data.descPL}
                                        </Col>
                                        <Col sm={6}>
                                            {this.state.data.descEN}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-2">
                                        <Col sm={10}>
                                            <b><FormattedMessage id="armor.part" />:</b><br />
                                            <ul>
                                                {armorParts}
                                            </ul>
                                        </Col>
                                        <Col sm={2}>
                                            <Button className="my-1" size="sm" onClick={this.onShow} variant="outline-primary">Edytuj</Button><br />
                                            <DeleteMeasurement measurementId={this.state.data.id} namePL={this.state.data.namePL} reloadData={this.props.reloadData} />
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        );
    }
}

import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './loading.css'
import loadingIcon from './loadingIcon.png';

export class Loading extends Component {
    displayName = Loading.name

    render() {
        return (
            <Container className="centering">
                <Row>
                    <Col sm="4">
                        <div className="loading-img">
                            <img
                                alt=""
                                src={loadingIcon}
                                width="128"
                                height="128"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import pl from 'react-intl/locale-data/pl';
import messages_pl from "./locale/pl.json";
import messages_en from "./locale/en.json";



import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

addLocaleData([...en, ...pl]);
const messages = {
    'pl': messages_pl,
    'en': messages_en
};
const language = navigator.language.split(/[-_]/)[0];


if (!localStorage.lang) {
    localStorage.lang = language;
}
const userLanguage = localStorage.lang;


ReactDOM.render(
    <IntlProvider
    locale={userLanguage}
    defaultLocale="en"
    messages={messages[userLanguage]}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>        
    </IntlProvider>,
  rootElement);

registerServiceWorker();

import React, { Component } from 'react';
import { Loading } from "../Loading/Loading";
import CartActions from "./CartActions";
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col, Form, InputGroup, Alert } from 'react-bootstrap';

export class CartShipping extends Component {
    displayName = CartShipping.name
    validateUrl = "api/shop/validate-shipping";

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            Loading: true,
            Error: "",
            validate: false,

            //form data
            fName: this.props.shippingData.fName,
            lName: this.props.shippingData.lName,
            email: this.props.shippingData.email,
            phone: this.props.shippingData.phone,
            street: this.props.shippingData.street,
            apartement: this.props.shippingData.apartement,
            country: this.props.shippingData.country,
            city: this.props.shippingData.city,
            zipCode: this.props.shippingData.zipCode,
            terms: this.props.shippingData.terms
        }


    }
    componentDidMount() {
        this.setState({ Loading: false })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        this.setState({ validate: true });

        if (form.checkValidity() === true) {
            this.serverSideValidation();
        }
    }

    serverSideValidation = () => {
        let shippingData = {
            fName: this.state.fName,
            lName: this.state.lName,
            email: this.state.email,
            phone: this.state.phone,
            street: this.state.street,
            apartement: this.state.apartement,
            country: this.state.country,
            city: this.state.city,
            zipCode: this.state.zipCode,
            terms: this.state.terms
        }
        this.setState({ Loading: true, Error: "" }, () => {
            fetch(this.validateUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(shippingData)
            })
                .then(response => response.body.length > 0 ? response.json() : {})
                .then(data => {
                    if (data.errorPL && data.errorPL.length > 0) {
                        this.setState({ Loading: false, Error: this.state.lang === "pl" ? data.errorPL : data.errorEN });
                    }
                    else {

                        this.props.onUpdateShipping(shippingData);
                        this.props.onNextButton();
                    }
                });
        });
    }

    handleChange = event => {
        let { name, value, checked, type } = event.target;
        if (type === "checkbox") {
            this.setState({ terms: checked });
        }
        else {
            this.setState({ [name]: value });
        }
    }

    render() {
        const validated = this.state.validate;

        if (this.state.Loading) {
            return (<Loading />);
        }

        return (
            <div>
                <h1 className="centering"><FormattedMessage id="h.shipppingHead" /></h1>
                <hr />

                {
                    this.state.Error.length > 0 &&
                    <Row className="centering">
                        <Col xs={12} sm={8} md={6} className="centering">
                            <Alert variant="danger" className="text-center">{this.state.Error}</Alert>
                        </Col>
                    </Row>
                }

                <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.handleSubmit(e)}
                >
                    <Form.Row className="centering text-center">
                        <Form.Group as={Col} md="4" lg="3" controlId="fName">
                            <Form.Label>{this.state.lang === "pl" ? "Imię" : "First Name"}:</Form.Label>
                            <Form.Control
                                name="fName"
                                value={this.state.fName}
                                type="text"
                                maxLength="100"
                                placeholder={this.state.lang === "pl" ? "Imię" : "First Name"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" lg="3" controlId="lName">
                            <Form.Label>{this.state.lang === "pl" ? "Nazwisko" : "Last Name"}:</Form.Label>
                            <Form.Control
                                name="lName"
                                value={this.state.lName}
                                type="text"
                                maxLength="100"
                                placeholder={this.state.lang === "pl" ? "Nazwisko" : "Last Name"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="centering text-center">
                        <Form.Group as={Col} md="4" lg="3" controlId="email">
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control
                                name="email"
                                value={this.state.email}
                                type="email"
                                maxLength="100"
                                placeholder="E-mail"
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" lg="3" controlId="lName">
                            <Form.Label>{this.state.lang === "pl" ? "Numer Telefonu" : "Phone Number"}:</Form.Label>
                            <Form.Control
                                name="phone"
                                value={this.state.phone}
                                type="tel"
                                maxLength="50"
                                placeholder={this.state.lang === "pl" ? "Numer Telefonu" : "Phone Number"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="centering text-center">
                        <Form.Group as={Col} md="4" lg="3" controlId="street">
                            <Form.Label>{this.state.lang === "pl" ? "Ulica" : "Street"}:</Form.Label>
                            <Form.Control
                                name="street"
                                value={this.state.street}
                                type="text"
                                maxLength="100"
                                placeholder={this.state.lang === "pl" ? "Ulica" : "Street"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" lg="3" controlId="apartement">
                            <Form.Label>{this.state.lang === "pl" ? "Numer Mieszkania" : "Apartement Number"}:</Form.Label>
                            <Form.Control
                                name="apartement"
                                value={this.state.apartement}
                                type="text"
                                maxLength="50"
                                placeholder={this.state.lang === "pl" ? "Numer Mieszkania" : "Apartement Number"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="centering text-center">
                        <Form.Group as={Col} md="3" lg="2" controlId="country">
                            <Form.Label>{this.state.lang === "pl" ? "Kraj" : "Country"}:</Form.Label>
                            <Form.Control
                                name="country"
                                value={this.state.country}
                                type="text"
                                maxLength="100"
                                placeholder={this.state.lang === "pl" ? "Kraj" : "Country"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="3" lg="2" controlId="city">
                            <Form.Label>{this.state.lang === "pl" ? "Miasto" : "City"}:</Form.Label>
                            <Form.Control
                                name="city"
                                value={this.state.city}
                                type="text"
                                maxLength="50"
                                placeholder={this.state.lang === "pl" ? "Miasto" : "City"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="3" lg="2" controlId="zipCode">
                            <Form.Label>{this.state.lang === "pl" ? "Kod Pocztowy" : "Postal Code"}:</Form.Label>
                            <Form.Control
                                name="zipCode"
                                value={this.state.zipCode}
                                maxLength="50"
                                placeholder={this.state.lang === "pl" ? "Kod Pocztowy" : "Postal Code"}
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="e.invalidInput" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="centering text-left">
                        <Form.Group as={Col} as={Col} xs="12" md="8" lg="6" controlId="terms">
                            <Form.Check type="checkbox" id={`termsCheckbox`}>
                                <Form.Check.Input
                                    type="checkbox"
                                    name="terms"
                                    required
                                    onChange={this.handleChange}
                                />
                                <Form.Check.Label>
                                    <FormattedMessage id="cart.termsText" />
                                </Form.Check.Label>

                                <Form.Control.Feedback type="invalid">
                                    <FormattedMessage id="e.termsNotAccepted" />
                                </Form.Control.Feedback>
                            </Form.Check>
                        </Form.Group>
                    </Form.Row>




                    <hr />

                    <Form.Row className="centering">
                        <Form.Group as={Col} xs="12" md="8" lg="6" className="centering">
                            <Button className="mx-2" variant="red-outline" onClick={this.props.onBackButton} ><FormattedMessage id="b.goback" /></Button>
                            <Button className="mx-2" variant="red" type="submit"><FormattedMessage id="b.nextstep" /></Button>
                        </Form.Group>
                    </Form.Row>


                </Form>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { SetCard } from './SetCard';
import { AddSet } from './AddSet';
import { MyPagination } from "../Pagination";
import { Loading } from '../../Loading/Loading';

export class Sets extends Component {
    displayName = Sets.name
    countUrl = "api/panel/get-sets-count"
    getUrl = "api/panel/get-sets"// + /{from}/{count}

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            from: 0,
            perPage: 50,
            selectedPage: 1,
            sets: [],
        }
    }

    componentDidMount() {
        this.fetchData(this.state.from);
    }

    handlePageChange = (number) => {
        this.setState({ isLoading: true, selectedPage: number });

        const newPage = parseInt(number);
        const newFrom = (newPage - 1) * this.state.perPage

        this.fetchData(newFrom);
    }

    fetchData = (from) => {

        if (!from) {
            from = this.state.from
        }
        this.setState({ isLoading: true }, () => {
            fetch(this.getUrl + "/" + from + "/" + this.state.perPage, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.JWT
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ sets: data, isLoading: false });
                });
        });
    }

    render() {

        if (this.state.isLoading) {
            return (<Loading />);
        }

        const updateTime = performance.now();
        const items = this.state.sets.map(s => <SetCard {...this.props} key={s.id + "_" + updateTime} set={s} reloadData={this.fetchData} />)
        return (
            <div>

                <h3 className="centering mb-3">Sety</h3>

                <AddSet reloadData={this.fetchData} />

                <div className="py-3">
                    {items}
                </div>

                <MyPagination countUrl={this.countUrl} active={this.state.selectedPage} handlePageChange={this.handlePageChange} perPage={this.state.perPage} />

            </div>
        )
    }
}

import React, { Component } from 'react';
import { Form, Alert, Button, Row, Col, Modal } from "react-bootstrap";

export class DeleteSet extends Component {
    displayName = DeleteSet.name
    deleteSetUrl = "api/panel/delete-set"//+/{id}"


    constructor(props) {
        super(props);
        this.state = {
            id: this.props.setId,
            namePL: this.props.namePL,
            ErrorMessage: "",
            modalShow: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, modalShow: false, ErrorMessage: "" });

        fetch(this.deleteSetUrl + "/" + parseInt(this.state.id), {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT

            }
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    this.setState({ modalShow: false })
                    this.props.reloadData();
                }
            });

    }

    onCancel = (event) => {
        this.setState({ modalShow: false });
    }
    onShow = (event) => {

        this.setState({ modalShow: true });
    }

    render() {
        return (
            <div>
                <Button className="my-1" size="sm" onClick={this.onShow} variant="outline-danger">Usuń</Button>

                <Modal show={this.state.modalShow} onHide={this.onCancel} size="lg" centered>
                    <Modal.Body>
                        <h5 className="centering">Usuń {this.state.namePL}</h5>
                        <hr />

                        {this.state.ErrorMessage.length > 0 &&
                            <Row className="centering">
                                <Col sm="4">
                                    <Alert variant="danger">
                                        {this.state.ErrorMessage}
                                    </Alert>
                                </Col>
                            </Row>
                        }
                        <h5 className="centering">Czy potwierdzasz usunięcie?</h5>

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Button className="my-1 mx-1" size="sm" variant="primary" type="submit">Tak</Button><br />
                                <Button className="my-1 mx-1" size="sm" onClick={this.onCancel} variant="outline-danger">Nie</Button>
                            </Form.Row>

                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Col, Card, Button, Modal, Form, Check, Row, Table } from "react-bootstrap";
import { Rating } from '../Shop/Rating';
import CartActions from "../Cart/CartActions";
import { FormattedMessage } from 'react-intl';


export class StockCard extends Component {
    displayName = StockCard.name;

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            data: this.props.stockItem,
            selectedGroupId: this.props.stockItem.stockItemGroups[0].id,

            modalShow: false,
        }
    }

    onAddToCartClick = (event) => {
        if (document.querySelectorAll('input[type=radio]:checked').length < 1) {
            return;
        }

        let armorType = "STOCK";
        CartActions.AddToCart({
            type: armorType,
            id: this.state.data.id,
            count: 1,
            measuremetsId: this.state.selectedGroupId
        });
        this.props.updateCartBadge();
        this.props.showGoToCartModal();
    }

    onModalHide = (event) => {
        this.setState({ modalShow: false });
    }
    onModalShow = (event) => {
        this.setState({ modalShow: true });
    }

    getMeasuremets = (joined) => {
        let measurements = [];
        let nameValues = joined.split("|");
        let langIndex = 0;
        if (localStorage.lang !== "pl") {
            langIndex = 1;
        }

        for (var i = 0; i < nameValues.length; i++) {
            let pair = nameValues[i].split(",");
            
            measurements.push({
                name: pair[0].split(";")[langIndex],
                value: pair[1],
            });
        }

        return measurements;
    }
    handleGroupClick = (event) => {
        this.setState({ selectedGroupId: +event.target.value });
    }

    render() {
        let imageSrc;

        if (this.state.data.photos) {
            imageSrc = `app-images/${this.state.data.photos[0]}`;
        }

        let inner;


        inner = this.state.data.stockItemGroups.map(gr =>

            <tr key={gr.id} onClick={() => document.getElementById(`radio-${gr.id}`).click()} className="group-select-row">

                <td className="my-auto">
                    <Form.Check
                        custom
                        type="radio"
                        id={`radio-${gr.id}`}
                        label=""
                        name="groupRadio"
                        //className="align-me"
                        value={gr.id}
                        onClick={this.handleGroupClick}
                    />
                </td>

                <td>
                    {this.getMeasuremets(gr.bodyMeasurementsNames).map(m => <span key={m.name}>{`${m.name}:`} &nbsp;&nbsp;  {`${m.value} cm`} <br /></span>)}
                </td>
            </tr >
        );

        return (
            <Col xl={3} lg={4} md={4} sm={6} className="my-3">

                <Modal show={this.state.modalShow} onHide={this.onModalHide} centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="h.chooseSize" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Table hover className="table-group">
                                    <tbody>
                                        {inner}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="centering">
                            <Button className="my-1 mx-1" size="sm" onClick={this.onModalHide} variant="outline-danger"><FormattedMessage id="button.cancel" /></Button>
                            <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onAddToCartClick}><FormattedMessage id="button.addToCart" /></Button><br />
                        </Row>

                    </Modal.Body>
                </Modal>

                <Card className="text-center" border="danger">
                    <a href={`/stock-details/${this.state.data.id}`}><Card.Img variant="top" src={imageSrc} /></a>
                    <Card.Body>
                        <a className="undecorated" href={`/stock-details/${this.state.data.id}`}>
                            <Card.Title className="my-2">{this.state.lang === "pl" ? this.state.data.namePL : this.state.data.nameEN}</Card.Title>
                        </a>
                        <Card.Text>{this.state.lang === "pl" ? this.state.data.shortDescPL : this.state.data.shortDescEN}</Card.Text>
                        <div className="centering">
                            <Rating apperanceRating={this.state.data.apperanceScore} mobilityRating={this.state.data.mobilityScore} defenceRating={this.state.data.defenceScore} />
                        </div>
                        <Card.Title>{this.state.data.price} €</Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        <Button className="m-1" variant="outline-danger" href={`/stock-details/${this.state.data.id}`}><FormattedMessage id="button.details" /></Button>
                        <Button className="m-1" variant="danger" onClick={this.onModalShow}><FormattedMessage id="button.addToCart" /></Button>
                    </Card.Footer>
                </Card>
            </Col>
        );
    }
}

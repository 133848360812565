import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu/NavMenu';
import './layout.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { FormattedMessage } from 'react-intl';

export class Layout extends Component {
    displayName = Layout.name

    render() {
        return (
            <Container fluid className="layout">

                <Row>
                    <Col>
                        <NavMenu cartItems={this.props.cartItems} />
                    </Col>
                </Row>

                <Row >
                    <Col lg={1} />
                    <Col lg={10} className="layout-main shadow-lg">
                        {this.props.children}
                    </Col>
                    <Col lg={1} />
                </Row>
                <CookieConsent
                    location="bottom"
                    buttonText={localStorage.lang === "pl" ? "Akceptuję" : "Accept"}
                    cookieName="GfWorkshopCookiesAccepted"
                    style={{ background: "#0f0f0f" }}
                    buttonStyle={{ background: "#bd2130", color: "white", fontSize: "13px", borderRadius: "4px" }}
                    expires={150}
                >
                    <FormattedMessage id="cookie.text" />{" "}
                    <a href={`privacy-policy/`}><FormattedMessage id="cookie.policy" /></a>
                </CookieConsent>
            </Container>
        );
    }
}

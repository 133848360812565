import React, { Component } from 'react';
import { Container} from 'react-bootstrap';


export class NotFound extends Component {
    displayName = NotFound.name

    render() {
        return (
            <Container className="my-3">

                <div className="text-center">
                    <h1>Page not found</h1><br/>
                    <h3>The page you tried to visit does not exist.</h3>
                    <h6>If you think it should, let us know by contacting us <a target="_blank" rel="noopener noreferrer" href="http://gacek.romadtp.pl/#contact">here</a></h6>
                </div>

            </Container>
        );
    }
}

import React, { Component } from 'react';
import { Form, Alert, Button, Row, Col, Modal } from "react-bootstrap";

export class EditSet extends Component {
    displayName = EditSet.name;
    getSetArmorPartsUrl = "api/panel/get-set-armorparts"// + /{setId}

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            armorParts: [],
            ErrorMessage: "",
            modalShow: false,

        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, ErrorMessage: "" });
        const ids = this.state.armorParts.filter(a => a.required).map(a => a.id);


        fetch('/api/panel/edit-set', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT
            },
            body: JSON.stringify({
                ID: this.state.data.id,
                NamePL: this.state.data.namePL,
                NameEN: this.state.data.nameEN,
                DescPL: this.state.data.descPL,
                DescEN: this.state.data.descEN,
                ShortDescPL: this.state.data.shortDescPL,
                ShortDescEN: this.state.data.shortDescEN,
                HoursToMake: this.state.data.hoursToMake,
                Price: this.state.data.price,
                ArmorPartsIds: ids
            })
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    this.setState({ modalShow: false })
                    this.props.reloadData();
                }
            });

    }

    handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === "checkbox") {
            
            const ap = this.state.armorParts.map(a => {
                if (parseInt(name) === a.id) {
                    a.required = checked;
                }
                return a;
            })
            this.setState({ armorParts: ap });
        }
        else {
            const data = { ...this.state.data }
            data[name] = value
            this.setState({ data: data });
        }
    }


    onCancel = (event) => {
        this.setState({ modalShow: false });
    }
    onShow = (event) => {
        fetch(this.getSetArmorPartsUrl + "/" + this.state.data.id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ armorParts: data, modalShow: true });
            });
    }

    render() {
        const armorPartCheckboxes = this.state.armorParts.map(ap =>
            <Form.Check
                id={`checkbox${ap.id}`}
                key={ap.id}
                type="checkbox"
                name={ap.id}
                label={`${ap.partName}`}
                checked={ap.required}
                onChange={this.handleChange}
            />
        )

        return (
            <div>
                <Button className="my-1" size="sm" onClick={this.onShow} variant="outline-primary">Edytuj</Button>

                <Modal show={this.state.modalShow} onHide={this.onCancel} size="lg" centered>
                    <Modal.Body>
                        <h5 className="centering">Edycja {this.state.data.namePL}</h5>
                        <hr />

                        {this.state.ErrorMessage.length > 0 &&
                            <Row className="centering">
                                <Col sm="4">
                                    <Alert variant="danger">
                                        {this.state.ErrorMessage}
                                    </Alert>
                                </Col>
                            </Row>
                        }


                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Nazwa polska:</Form.Label>
                                    <Form.Control name="namePL" type="text" placeholder="Nazwa polska" value={this.state.data.namePL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Nazwa angielska:</Form.Label>
                                    <Form.Control name="nameEN" type="text" placeholder="Nazwa angielska" value={this.state.data.nameEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descPL" type="text" placeholder="Opis polski" value={this.state.data.descPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descEN" type="text" placeholder="Opis angielski" value={this.state.data.descEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescPL" type="text" placeholder="Krótki opis polski" value={this.state.data.shortDescPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescEN" type="text" placeholder="Krótki opis angielski" value={this.state.data.shortDescEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Czas produkcji (w godzinach):</Form.Label>
                                    <Form.Control name="hoursToMake" type="number" placeholder="Czas produkcji" value={this.state.data.hoursToMake} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Cena (EUR):</Form.Label>
                                    <Form.Control name="price" type="number" placeholder="Cena" value={this.state.data.price} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col>
                                    <Form.Label>Części zbroi:</Form.Label>
                                    {armorPartCheckboxes}
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Button className="my-1 mx-1" size="sm" variant="primary" type="submit">Zatwierdź</Button><br />
                                <Button className="my-1 mx-1" size="sm" onClick={this.onCancel} variant="outline-danger">Anuluj</Button>
                            </Form.Row>

                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


import React, { Component } from 'react';
import { Loading } from "../Loading/Loading";
import CartActions from "./CartActions";
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col, Form, InputGroup, Alert } from 'react-bootstrap';

export class CartMeasurements extends Component {
    displayName = CartMeasurements.name
    requestUrl = "api/shop/get-parts-measurements";
    validateUrl = "api/shop/validate-measurements";

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            measurements: this.props.measurementsData,
            Loading: true,
            Error: "",
            validate: false
        }


    }

    componentDidMount() {
        if (!this.state.measurements || this.state.measurements.length < 1) {
            this.fetchData();
        }
        else {
            this.setState({ Error: "", Loading: false });
        }
    }

    fetchData() {
        let cartItems = CartActions.GetCartItems().map(ci => ci.id);
        if (cartItems.length < 1) {
            this.setState({
                Loading: false,
                items: []
            });
            return;
        }

        this.setState({ Loading: true, Error: "" }, () => {
            fetch(this.requestUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cartItems)
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ measurements: data, Loading: false }, () => {
                        if (this.state.measurements.length < 1) {
                            this.props.onNextButton();
                        }});
                });
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        this.setState({ validate: true });

        if (form.checkValidity() === true) {
            this.serverSideValidation();
        }
    }

    serverSideValidation = () => {
        this.setState({ Loading: true, Error: "" }, () => {
            fetch(this.validateUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.measurements)
            })
                .then(response => response.body.length > 0 ? response.json() : {})
                .then(data => {
                    if (data.errorPL && data.errorPL.length > 0) {
                        this.setState({ Loading: false, Error: this.state.lang === "pl" ? data.errorPL : data.errorEN });
                    }
                    else {
                        this.props.onUpdateMeasurements(this.state.measurements);
                        this.props.onNextButton();
                    }
                });
        });
    }


    handleMeasurementChange = id => evt => {
        const newMeasurements = this.state.measurements.map(m => {
            if (m.id === id) m.value = evt.target.value;
            return m
        });

        this.setState({ measurements: newMeasurements });

    };

    render() {
        const validated = this.state.validate;

        let measuremetInputs = this.state.measurements.map(m =>
            <Form.Row className="centering" key={m.id}>
                <Form.Group as={Col} md="4" lg="3" controlId={m.id}>
                    <Form.Label>{this.state.lang === "pl" ? m.namePL : m.nameEN}:</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            min="0"
                            max="10000000"
                            step="0.01"
                            placeholder={this.state.lang === "pl" ? m.namePL : m.nameEN}
                            aria-describedby="inputGroupPrepend"
                            required
                            onChange={this.handleMeasurementChange(m.id)}
                            defaultValue={m.value}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="e.invalidMeasurement" />
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Form.Row>
        );

        if (this.state.Loading) {
            return (<Loading />);
        }

        return (
            <div>
                <h1 className="centering"><FormattedMessage id="h.measurementHead" /></h1>
                <hr />
                <h4 className="centering my-3 text-center"><FormattedMessage id="h.measurementsText" /></h4>
                <Row className="centering">
                    <Col xs={12} sm={8} md={6} className="centering">
                        <Alert variant="info" className="text-center"><FormattedMessage id="c.instructions" /><a href="/app-images/measurements.jpg" target="_blank"><b>{this.state.lang === "pl" ? "instrukcją" : "instruction"}</b>.</a></Alert>
                    </Col>
                </Row>
                <hr />

                {
                    this.state.Error.length > 0 &&
                    <Row className="centering">
                        <Col xs={12} sm={8} md={6} className="centering">
                            <Alert variant="danger" className="text-center">{this.state.Error}</Alert>
                        </Col>
                    </Row>
                }

                <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.handleSubmit(e)}
                >
                    {measuremetInputs}

                    <hr />

                    <Form.Row className="centering">
                        <Form.Group as={Col} xs="12" className="centering">
                            <Button className="mx-2" variant="red-outline" onClick={this.props.onBackButton} ><FormattedMessage id="b.goback" /></Button>
                            <Button className="mx-2" variant="red" type="submit"><FormattedMessage id="b.nextstep" /></Button>
                        </Form.Group>
                    </Form.Row>


                </Form>
            </div>
        );
    }
}

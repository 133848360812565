import React, { Component } from 'react';
import { Form, Button, Row, Col, Alert } from "react-bootstrap"
import "./login.css"
import UserManager from "./JWTAuth"
import { Loading } from "../Loading/Loading"

export class Login extends Component {
    displayName = Login.name
    constructor() {
        super();
        this.state = {
            UserName: "",
            Password: "",
            Remember: true,
            ErrorMessage: "",
            isLoading: true
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true });

        fetch('/api/account/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                UserName: this.state.UserName,
                Password: this.state.Password,
                Remember: true
            })
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    UserManager.SetJWT(data.token)
                    this.setState({ ErrorMessage: "" })
                    this.props.history.push("/admin");
                }
            });
        this.setState({ isLoading: false });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {

        return (
            <div>
                <h1 className="centering">Log in</h1>
                <hr />

                {this.state.isLoading &&
                    <Loading />
                }

                {this.state.ErrorMessage.length > 0 &&
                    <Row className="centering">
                        <Col sm="4">
                            <Alert variant="danger">
                                {this.state.ErrorMessage}
                            </Alert>
                        </Col>
                    </Row>
                }


                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>

                        <Col sm="4">
                            <Form.Label>User name</Form.Label>
                            <Form.Control name="UserName" type="text" placeholder="Enter User name" value={this.state.UserName} onChange={this.handleChange} />
                        </Col>


                    </Form.Row>

                    <Form.Row>

                        <Col sm="4">
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="Password" type="password" placeholder="Password" value={this.state.Password} onChange={this.handleChange} />
                        </Col>

                    </Form.Row>

                    <Form.Row>
                        <Col sm="4" className="centering">
                            <Button variant="red" type="submit">
                                Log in
                            </Button>
                        </Col>
                    </Form.Row>

                </Form>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { StockItemCard } from './StockItemCard';
import { StockItemForm } from './StockItemForm';
import { MyPagination } from "../Pagination";
import { Loading } from "../../Loading/Loading";

export class StockItems extends Component {
    displayName = StockItems.name
    countUrl = "api/panel/get-stock-count"
    getUrl = "api/panel/get-stock"// + /{from}/{count}

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            from: 0,
            perPage: 50,
            selectedPage: 1,
            stockItems: [],
        }
    }

    componentDidMount() {
        //this.setState({ isLoading: false })
        this.fetchData(this.state.from);
    }

    handlePageChange = (number) => {
        this.setState({ isLoading: true, selectedPage: number });

        const newPage = parseInt(number);
        const newFrom = (newPage - 1) * this.state.perPage

        this.fetchData(newFrom);
    }

    fetchData = (from) => {

        if (!from) {
            from = this.state.from;
        }

        this.setState({ isLoading: true }, () => {
            fetch(this.getUrl + "/" + from + "/" + this.state.perPage, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.JWT
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ stockItems: data, isLoading: false });
                });
        });
    }


    render() {

        if (this.state.isLoading) {
            return (<Loading />);
        }

        const addData = {
            id: 0,
            namePL: "",
            nameEN: "",
            descPL: "",
            descEN: "",
            shortDescPL: "",
            shortDescEN: "",
            hoursToMake: 0,
            price: 0,
            typePL: "",
            isAvailable: true,
            mobilityScore: 0,
            defenceScore: 0,
            apperanceScore: 0,
            stockItemGroups: []
        }

        const updateTime = performance.now();
        const stockItems = this.state.stockItems.map(s => <StockItemCard {...this.props} key={s.id + "_" + updateTime} stockItem={s} reloadData={this.fetchData} />)
        return (
            <div>
                <h3 className="centering mb-3">Na magazynie</h3>

                <StockItemForm data={addData} action="add" reloadData={this.fetchData} />

                <div className="py-3">
                    {stockItems}
                </div>

                <MyPagination countUrl={this.countUrl} active={this.state.selectedPage} handlePageChange={this.handlePageChange} perPage={this.state.perPage} />

            </div>
        )
    }
}
import React, { Component } from 'react';
import { OrdersList } from './OrdersList';
import { StatusNav } from './StatusNav';

export class Orders extends Component {
    displayName = Orders.name
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            currentStatus: "New"
        }
    }

    handleStatusChange = (event) => {
        this.setState({ currentStatus: event });
    }


    render() {
        return (
            <div>
                <h3 className="centering mb-4">Zamówienia</h3>

                <StatusNav tab={this.state.currentStatus} handleStatusChange={this.handleStatusChange} />

                <OrdersList status={this.state.currentStatus} />

            </div>
        );
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './NavMenu.css';
import logo from "./logo.png";
import en from "../en.png";
import pl from "../pl.png";

export class NavMenu extends Component {
    displayName = NavMenu.name
    constructor(props) {
        super(props);

        this.state = {
            cartItems: this.props.cartItems
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        const lang = event.target.id;
        if (lang === localStorage.lang) {
            return;
        }
        localStorage.lang = lang;
        window.location.reload();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cartItems !== this.props.cartItems) {
            this.setState({ cartItems: nextProps.cartItems })
        }
    }

    render() {

        return (
            <Navbar collapseOnSelect expand="lg" fixed="top" variant="dark">
                <Navbar.Brand>
                        <a href={`shop/`}>
                            <img
                                alt=""
                                src={logo}
                                className="logo"
                            />
                        </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">

                        <Nav.Item>
                            <Link to={'/shop'}>
                                <FormattedMessage id="nav.home" />
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={'/stock'}>
                                <FormattedMessage id="nav.stock" />
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={'/gallery'}>
                                <FormattedMessage id="nav.gallery" />
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to={'/contact'}>
                                <FormattedMessage id="nav.contact" />
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to={'/about'}>
                                <FormattedMessage id="nav.about" />
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to={'/cart'}>
                                <FormattedMessage id="nav.cart" />
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to={'/cart'}>
                                <i className="fa fa-shopping-cart"></i>
                                <span className='badge badge-warning' id='lblCartCount'> {this.state.cartItems} </span>
                            </Link>
                        </Nav.Item>

                        <Nav.Item>

                            <Link onClick={this.handleClick} id="en" to="#">
                                <img
                                    alt=""
                                    src={en}
                                    height="20px"
                                    className="d-inline-block align-middle mr-1"
                                />
                                EN
                                </Link>

                            <span>|</span>

                            <Link onClick={this.handleClick} id="pl" to="#">
                                PL
                                <img
                                    alt=""
                                    src={pl}
                                    height="20px"
                                    className="d-inline-block align-middle ml-1"
                                />

                            </Link>

                        </Nav.Item>

                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        );
    }
}

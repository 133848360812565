import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

export class PrivacyPolicy extends Component {
    displayName = PrivacyPolicy.name

    render() {
        return (
            <Row className="centering">
                <Col md={8}>
                    <h1 className="text-center my-3"><FormattedMessage id="policy.head" /></h1>
                    <h6 className="my-5"><FormattedHTMLMessage id="policy.content" /></h6>
                </Col>
            </Row>
        );
    }
}

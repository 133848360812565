import React, { Component } from 'react';
import { Form, Alert, Button, Row, Col, Modal } from "react-bootstrap";
import { Loading } from "../../Loading/Loading"

export class StockItemGroup extends Component {
    displayName = StockItemGroup.name
    getMeasurementsUrl = "api/panel/get-measurements/{0}/{9999}" // /{partId}
    postUrl = "api/panel/edit-stock"


    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
        }
    }

    handleChange = (event) => {
        let value = event.target.value;
        this.setState((prevState) => {
            let newData = prevState.data;
            newData.count = value;
            return { data: newData }
        }, () => {
            this.props.onUpdateGroup(this.state.data);
        });
    }
    onDeleteGroup = () => {
        this.props.onDeleteGroup(this.state.data.id);
    }

    render() {

        let inner;
        if (this.state.data.bodyMeasurementsNames.length > 0) {
            let measurements = [];
            let nameValues = this.state.data.bodyMeasurementsNames.split("|");


            for (var i = 0; i < nameValues.length; i++) {
                let pair = nameValues[i].split(",");
                measurements.push({
                    name: pair[0].split(";")[0],
                    value: pair[1],
                });
            }

            inner = measurements.map(m => <tr key={m.name}><td>{m.name}</td><td>{m.value}</td></tr>);
        }

        return (
            <div>
                <hr />
                <Form.Row className="align-me centering">
                    <Col sm={6}>
                        <table className="w-75">
                            <tbody>
                                {inner}
                            </tbody>
                        </table>
                    </Col>
                    <Col sm={3}>
                        <Form.Control name="count" type="number" min="0" placeholder="W magazynie" value={this.state.data.count} onChange={this.handleChange} disabled={!this.props.editable} />
                    </Col>
                    <Col sm={1}>
                        {this.props.editable &&
                            <Button variant="outline-danger" size="sm" onClick={this.onDeleteGroup}>Usuń</Button>
                        }
                    </Col>
                </Form.Row>
            </div>
        );
    }
}

import jwt_decode from 'jwt-decode';

export default class UserManager {

    static SetJWT(token) {
        localStorage.JWT = token;
    }
    static RemoveJWT() {
        localStorage.removeItem("JWT");
    }

    static GetJWT() {
        return localStorage.JWT;
    }

    static IsJWTExpired() {
        var isExpired = false;
        const token = localStorage.JWT;
        var decodedToken = jwt_decode(token);
        var dateNow = new Date();
        if (decodedToken.exp * 1000 < dateNow.getTime())
            isExpired = true;

        return isExpired;
    }


}
import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

export class CartItem extends Component {
    displayName = CartItem.name

    constructor(props) {
        super(props);

        this.state = {
            lang: localStorage.lang,
            id: this.props.data.id,
            photo: this.props.data.photo,
            namePL: this.props.data.namePL,
            nameEN: this.props.data.nameEN,
            price: this.props.data.price,
            count: this.props.data.count,
            type: this.props.data.type,
            totalPrice: this.props.data.totalPrice,
            stockCount: this.props.data.stockCount,

            addRemove: 1,
        }


    }

    componentDidMount() {
    }

    handleChange = (event) => {
        const { value } = event.target;

        if (value > this.state.stockCount || value < 1) {
            return;
        }

        this.setState((prevState, props) => {
            this.props.updateTotalPrice((value * prevState.price) - prevState.totalPrice);
            let newValue = value > this.state.stockCount || value < 1 ? +prevState.count : value;
            return ({
                count: newValue,
                totalPrice: newValue * prevState.price,
                addRemove: +prevState.count > newValue ? -1 : 1,
            });
        }, () => {
            //this.props.updateTotalPrice(this.state.totalPrice, this.state.id);
                if (this.state.count === 0) {
                    this.props.deleteItems(this.state.id, this.state.type);
                }
                if (this.state.addRemove === 1) {
                    this.props.addItem(this.state.id, this.state.type, this.state.count);
                }
                else if (this.state.addRemove === -1) {
                    this.props.removeItem(this.state.id, this.state.type, this.state.count);
                }
        });
    }

    deleteItems = () => {
        this.props.updateTotalPrice(this.state.totalPrice * -1);
        this.props.deleteItems(this.state.id, this.state.type);
    }

    render() {
        let max;
        let countInput;

        max = this.state.type === "GAUNTLETS" || this.state.type === "STOCK" ? this.state.stockCount : 1;
        countInput = <Form>
            <Form.Control
                className="count-input"
                type="number"
                name="count"
                min="1" max={max}
                value={this.state.count}
                onChange={this.handleChange}
                disabled={!(this.state.type === "GAUNTLETS" || this.state.type === "STOCK")}
        />
        </Form>

        return (
            <div>

                <Row className="centering">
                    <Col xl={8} lg={10} md={10} sm={12}>
                        <Row className="align-me centering">
                            <Col xs={4} md="auto">
                                <a href={this.state.type === "STOCK" ? `/stock-details/${this.state.id}` : `details/${this.state.id}`}><img alt="Details" src={`app-images/${this.state.photo}`} className="img-thumbnail cart-image mx-2" /></a>
                            </Col>
                            <Col>
                                <a className="undecorated" href={this.state.type === "STOCK" ? `/stock-details/${this.state.id}` : `details/${this.state.id}`}><h5>{this.state.lang === "pl" ? this.state.namePL : this.state.nameEN}</h5></a>
                            </Col>
                            <Col xs={3}>
                                <h6>{this.state.totalPrice} &euro;</h6>
                            </Col>
                            <Col xs={2}>
                                {countInput}
                            </Col>
                            <Col xs={1}>
                                <i onClick={this.deleteItems} className="fa fa-trash fa-lg" aria-hidden="true"></i>
                            </Col>
                        </Row>
                        <hr />
                    </Col>
                </Row>
            </div>
        );
    }
}

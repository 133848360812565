import React, { Component } from 'react';
import { Button } from "react-bootstrap";

export class UpdateStatusButton extends Component {
    displayName = UpdateStatusButton.name
    updateUrl = "api/panel/update-order-status"//+/{status}/{id}"


    constructor(props) {
        super(props);
        this.state = {
            id: this.props.orderId,
            status: this.props.status,
        }
    }

    handleClick = (event) => {
        this.setState({ isLoading: true });

        fetch(this.updateUrl + "/" + this.state.status + "/" + this.state.id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT

            }
        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.props.reloadData();
            });

    }

    render() {
        return (
            <Button className="my-1" size="sm" onClick={this.handleClick} variant="outline-primary">{this.props.buttonText}</Button>
        );
    }
}

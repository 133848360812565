import React, { Component } from 'react';
import { Form, Alert, Button, Row, Col, Modal } from "react-bootstrap";
import { Loading } from "../../Loading/Loading"
import { StockItemGroup } from './StockItemGroup';

export class StockItemForm extends Component {
    displayName = StockItemForm.name
    getMeasurementsUrl = "api/panel/get-measurements/0/999" // /{partId}
    postUrl = "api/panel/edit-stock"


    constructor(props) {
        super(props);

        this.state = {

            action: this.props.action,

            id: this.props.data.id,
            namePL: this.props.data.namePL,
            nameEN: this.props.data.nameEN,
            descPL: this.props.data.descPL,
            descEN: this.props.data.descEN,
            shortDescPL: this.props.data.shortDescPL,
            shortDescEN: this.props.data.shortDescEN,
            price: this.props.data.price,
            type: this.props.data.type,
            isAvailable: this.props.data.isAvailable,
            mobilityScore: this.props.data.mobilityScore,
            defenceScore: this.props.data.defenceScore,
            apperanceScore: this.props.data.apperanceScore,
            stockItemGroups: this.props.data.stockItemGroups,

            measurements: [],

            photos: [],
            uploadedPhotos: [],

            ErrorMessage: "",

            modalShow: false,
            addGroupModalShow: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, modalShow: false, ErrorMessage: "" });

        let fd = new FormData()
        for (var x = 0; x < this.state.uploadedPhotos.length; x++) {
            fd.append("Images", this.state.uploadedPhotos[x]);
        }

        fd.append("Id", this.state.id);
        fd.append("NamePL", this.state.namePL);
        fd.append("NameEN", this.state.nameEN);
        fd.append("DescPL", this.state.descPL);
        fd.append("DescEN", this.state.descEN);
        fd.append("ShortDescPL", this.state.shortDescPL);
        fd.append("ShortDescEN", this.state.shortDescEN);
        fd.append("Price", this.state.price);
        fd.append("type", "STOCK");
        fd.append("isAvailable", this.state.isAvailable);
        fd.append("mobilityScore", this.state.mobilityScore);
        fd.append("defenceScore", this.state.defenceScore);
        fd.append("apperanceScore", this.state.apperanceScore);
        fd.append("stockItemGroupsJson", JSON.stringify(this.state.stockItemGroups))
        fd.append("group", "STOCK")

        fetch(this.postUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT

            },
            body: fd

        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    this.setState({ modalShow: false })
                    this.props.reloadData();
                }
            });

    }

    handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === "checkbox") {
            this.setState({ isAvailable: checked });
        }
        else {
            this.setState({ [name]: value });
        }
    }

    onCancel = (event) => {
        this.setState({ modalShow: false });
    }
    onShow = (event) => {
        fetch(this.getMeasurementsUrl, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ measurements: data, modalShow: true });
            });
    }

    onGroupCancel = (event) => {
        this.setState({ addGroupModalShow: false, modalShow: true });
    }
    onShowAddGroup = (event) => {
        this.setState({ addGroupModalShow: true, modalShow: false });
    }

    handleImagesChange = (event) => {
        this.setState({
            uploadedPhotos: [...event.target.files]
        });
    }


    onUpdateGroup = (group) => {
        this.setState((prevState) => {

            let newGroups = prevState.stockItemGroups;
            for (var i = 0; i < newGroups.length; i++) {
                if (newGroups[i].id === group.id) {
                    newGroups[i] = group;
                }
            }

            return { stockItemGroups: newGroups }
        })
    }
    onDeleteGroup = (id) => {
        this.setState((prevState) => {
            let newGroups = prevState.stockItemGroups.filter(g => g.id !== id);
            return { stockItemGroups: newGroups }
        })
    }

    onAddGroup = () => {
        let form = document.forms[0];
        let addcount = form.querySelector('input[name = addGroupCount]').value;

        let bodyMeasurementNames = [];
        let inputs = form.querySelectorAll('input[name ^= "measValue|"]') 
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].value > 0) {
                bodyMeasurementNames.push(`${inputs[i].name.split("|")[1]},${inputs[i].value}`);
            }
            
        }
        let joinedMeas = bodyMeasurementNames.join("|");

        if (joinedMeas.length < 1) {
            this.onGroupCancel();
            return;
        }

        this.setState((prevState) => {
            let newGroups = prevState.stockItemGroups;
            let maxId = Math.max.apply(Math, newGroups.map(g => g.id));
            if (maxId < 1) {
                maxId = 0;
            }
            newGroups.push({
                id: +maxId + 1,
                bodyMeasurementsNames: joinedMeas,
                count: addcount
            });
            return { stockItemGroups: newGroups, addGroupModalShow: false, modalShow: true }

        })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        }

        let button;
        if (this.state.action === "add") {
            button = (
                <Row className="centering">
                    <Col className="centering" sm={12}>
                        <Button className="mt-2" variant="primary" onClick={this.onShow}>Dodaj Nowy</Button>
                    </Col>
                </Row>
            )

        }
        else if (this.state.action === "edit") {
            button = (
                <Button className="mt-2" size="sm" variant="outline-primary" onClick={this.onShow}>Edytuj</Button>
            )
        }

        let images;
        if (this.state.photos.length > 0) {
            let list = this.state.photos.map(im => <p key={im.name}>&bull; {im.name}</p>)
            images = (
                <Form.Row>
                    <Col >
                        <Form.Label>Aktualne zdjęcia:</Form.Label>
                        {list}
                    </Col>
                </Form.Row>
            );
        }

        let uploadedImages;
        if (this.state.uploadedPhotos.length > 0) {
            let list = this.state.uploadedPhotos.map(im => <p key={im.name}>&bull; {im.name}</p>)
            uploadedImages = (
                <Form.Row>
                    <Col >
                        <Form.Label>Nowe zdjęcia:</Form.Label>
                        {list}
                    </Col>
                </Form.Row>
            );
        }

        let measurementGroups = this.state.stockItemGroups.map(sig => <StockItemGroup key={sig.id} data={sig} onUpdateGroup={this.onUpdateGroup} onDeleteGroup={this.onDeleteGroup} editable={true} />)
        if (measurementGroups.length < 1) {
            measurementGroups = <h5 className="text-center">Dodaj dostępne wymiary!</h5>
        }

        let inner = this.state.measurements.map(m => <tr key={m.namePL}>
            <td>{m.namePL}</td>
            <td>
                <Form.Control size="sm" name={`measValue|${m.namePL};${m.nameEN}`} type="number" min="0" defaultValue="0" />
            </td>
        </tr>);

        return (
            <div>
                {button}

                <Modal show={this.state.addGroupModalShow} onHide={this.onGroupCancel} size="lg" centered>
                    <Modal.Body>
                        <h5 className="centering">Dodaj dostępny rozmiar</h5>
                        <hr />
                        <Form>
                            <Form.Row className="align-me centering text-center">
                                <Col sm={6}>
                                    <table className="w-100">
                                        <tbody>
                                            {inner}
                                        </tbody>
                                    </table>
                                </Col>
                                <Col sm={3}>
                                    <Form.Label>Dostępnych sztuk:</Form.Label>
                                    <Form.Control size="sm" name="addGroupCount" type="number" min="1" placeholder="W magazynie" defaultValue="0" />
                                </Col>

                            </Form.Row>

                            <Form.Row className="centering mt-3">
                                <Button className="my-1 mx-1" size="sm" variant="primary" onClick={this.onAddGroup}>Dodaj</Button><br />
                                <Button className="my-1 mx-1" size="sm" onClick={this.onGroupCancel} variant="outline-danger">Anuluj</Button>
                            </Form.Row>
                        </Form>

                    </Modal.Body>
                </Modal>

                <Modal show={this.state.modalShow} onHide={this.onCancel} size="lg" centered>

                    <Modal.Body>
                        <h5 className="centering">{this.state.action === "add" ? "Dodaj" : "Modyfikuj"} zbroje w magazynie</h5>
                        <hr />

                        {this.state.ErrorMessage.length > 0 &&
                            <Row className="centering">
                                <Col sm="4">
                                    <Alert variant="danger">
                                        {this.state.ErrorMessage}
                                    </Alert>
                                </Col>
                            </Row>
                        }


                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Nazwa polska:</Form.Label>
                                    <Form.Control name="namePL" type="text" placeholder="Nazwa polska" value={this.state.namePL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Nazwa angielska:</Form.Label>
                                    <Form.Control name="nameEN" type="text" placeholder="Nazwa angielska" value={this.state.nameEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descPL" type="text" placeholder="Opis polski" value={this.state.descPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descEN" type="text" placeholder="Opis angielski" value={this.state.descEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescPL" type="text" placeholder="Krótki opis polski" value={this.state.shortDescPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescEN" type="text" placeholder="Krótki opis angielski" value={this.state.shortDescEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row className="centering">
                                <Col sm={6}>
                                    <Form.Label>Cena (EUR):</Form.Label>
                                    <Form.Control name="price" type="number" placeholder="Cena" value={this.state.price} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={4}>
                                    <Form.Label>Mobility score:</Form.Label>
                                    <Form.Control name="mobilityScore" type="number" min="0" max="5" placeholder="Mobility score" value={this.state.mobilityScore} onChange={this.handleChange} />
                                </Col>
                                <Col sm={4}>
                                    <Form.Label>Defence score:</Form.Label>
                                    <Form.Control name="defenceScore" type="number" min="0" max="5" placeholder="Defence score" value={this.state.defenceScore} onChange={this.handleChange} />
                                </Col>
                                <Col sm={4}>
                                    <Form.Label>Apperance score:</Form.Label>
                                    <Form.Control name="apperanceScore" type="number" min="0" max="5" placeholder="Apperance score" value={this.state.apperanceScore} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <hr />
                            <Form.Row className="align-me centering">
                                <Col sm={6}>
                                    <Form.Label>Wymiary</Form.Label>
                                </Col>
                                <Col sm={3}>
                                    <Form.Label>Sztuk w magazynie</Form.Label>
                                </Col>
                                <Col sm={1}>
                                    <Button variant="outline-primary" size="sm" onClick={this.onShowAddGroup}>Nowy</Button>
                                </Col>
                            </Form.Row>

                            {measurementGroups}
                            <hr />

                            <Form.Row>
                                <Col >
                                    <Form.Label>Widoczność w sklepie:</Form.Label>
                                    <Form.Check
                                        id="available"
                                        type="checkbox"
                                        name="available"
                                        label="Dostępny"
                                        checked={this.state.isAvailable}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Form.Row>

                            {images}

                            <Form.Row>
                                <Col>
                                    <Form.Label>Wybierz nowe zdjęcia:</Form.Label><br />
                                    <input type="file" multiple onChange={this.handleImagesChange} />
                                </Col>
                            </Form.Row>

                            {uploadedImages}


                            <Form.Row>
                                <Button className="my-1 mx-1" size="sm" variant="primary" type="submit">Zatwierdź</Button><br />
                                <Button className="my-1 mx-1" size="sm" onClick={this.onCancel} variant="outline-danger">Anuluj</Button>
                            </Form.Row>

                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

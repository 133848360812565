import React, { Component } from 'react';
import { Form, Alert, Button, Row, Col, Modal } from "react-bootstrap";
import { Loading } from "../../Loading/Loading"

export class PartForm extends Component {
    displayName = PartForm.name
    getMeasurementsUrl = "api/panel/get-part-measurements" // /{partId}
    getSetsUrl = "api/panel/get-part-sets" // /{partId}
    getTypesUrl = "api/panel/get-part-types/PL"
    postUrl = "api/panel/edit-part"


    constructor(props) {
        super(props);

        this.state = {

            action: this.props.action,

            id: this.props.data.id,
            namePL: this.props.data.namePL,
            nameEN: this.props.data.nameEN,
            descPL: this.props.data.descPL,
            descEN: this.props.data.descEN,
            shortDescPL: this.props.data.shortDescPL,
            shortDescEN: this.props.data.shortDescEN,
            hoursToMake: this.props.data.hoursToMake,
            price: this.props.data.price,
            typePL: this.props.data.typePL,
            isAvailable: this.props.data.isAvailable,
            mobilityScore: this.props.data.mobilityScore,
            defenceScore: this.props.data.defenceScore,
            apperanceScore: this.props.data.apperanceScore,

            measurements: [],
            armorSets: [],
            partTypes: [],

            photos: [],
            uploadedPhotos: [],

            ErrorMessage: "",

            modalShow: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true, modalShow: false, ErrorMessage: "" });
        const mIds = this.state.measurements.filter(a => a.required).map(a => a.id);
        const sIds = this.state.armorSets.filter(a => a.required).map(a => a.id);

        let fd = new FormData()
        for (var x = 0; x < this.state.uploadedPhotos.length; x++) {
            fd.append("Images", this.state.uploadedPhotos[x]);
        }
        for (var y = 0; y < sIds.length; y++) {
            fd.append("armorSetsIds", sIds[y]);
        }
        for (var z = 0; z < mIds.length; z++) {
            fd.append("measurementsIds", mIds[z]);
        }
        fd.append("Id", this.state.id);
        fd.append("NamePL", this.state.namePL);
        fd.append("NameEN", this.state.nameEN);
        fd.append("DescPL", this.state.descPL);
        fd.append("DescEN", this.state.descEN);
        fd.append("ShortDescPL", this.state.shortDescPL);
        fd.append("ShortDescEN", this.state.shortDescEN);
        fd.append("HoursToMake", this.state.hoursToMake);
        fd.append("Price", this.state.price);
        fd.append("typePL", this.state.typePL);
        fd.append("isAvailable", this.state.isAvailable);
        fd.append("mobilityScore", this.state.mobilityScore);
        fd.append("defenceScore", this.state.defenceScore);
        fd.append("apperanceScore", this.state.apperanceScore);

        fetch(this.postUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.JWT

            },
            body: fd

        })
            .then((response) => {
                return response.json();

            }).then((data) => {
                this.setState({ isLoading: false });
                if (data.error) {
                    this.setState({ ErrorMessage: data.error })
                }
                else {
                    this.setState({ modalShow: false })
                    this.props.reloadData();
                }
            });

    }

    handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === "checkbox") {

            if (name.includes("measurement")) {
                const meas = this.state.measurements.map(m => {
                    if (parseInt(name.match(/\d/g).join("")) === m.id) {
                        m.required = checked;
                    }
                    return m;
                })
                this.setState({ measurements: meas });
            }

            else if (name.includes("armorSet")) {
                const sets = this.state.armorSets.map(s => {
                    if (parseInt(name.match(/\d/g).join("")) === s.id) {
                        s.required = checked;
                    }
                    return s;
                })
                this.setState({ armorSets: sets });
            }

            else if (name.includes("available")) {
                this.setState({ isAvailable: checked });
            }

        }
        else {
            this.setState({ [name]: value });
        }
    }

    onCancel = (event) => {
        this.setState({ modalShow: false });
    }
    onShow = (event) => {
        fetch(this.getMeasurementsUrl + "/" + parseInt(this.state.id), {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ measurements: data, modalShow: true });
            });

        fetch(this.getSetsUrl + "/" + parseInt(this.state.id), {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ armorSets: data, modalShow: true });
            });

        fetch(this.getTypesUrl, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.JWT
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ partTypes: data, modalShow: true });
                if (this.state.typePL === "") {
                    this.setState({ typePL: data[0]});
                }
            });
    }

    handleImagesChange = (event) => {
        this.setState({
            uploadedPhotos: [...event.target.files]
        });
    }

    render() {
        //console.log(this.state)

        if (this.state.isLoading) {
            return (
                <Loading />
            );
        }


        const typeSelectInner = this.state.partTypes.map(t => <option key={t} value={t}>{t}</option>)


        const measurementsCheckboxes = this.state.measurements.map(m =>
            <Form.Check
                id={`measurement${m.id}`}
                key={m.id}
                type="checkbox"
                name={`measurement${m.id}`}
                label={`${m.namePL}`}
                checked={m.required}
                onChange={this.handleChange}
            />);

        const armorSetsCheckboxes = this.state.armorSets.map(s =>
            <Form.Check
                id={`armorSet${s.id}`}
                key={s.id}
                type="checkbox"
                name={`armorSet${s.id}`}
                label={`${s.namePL}`}
                checked={s.required}
                onChange={this.handleChange}
            />);

        let button;
        if (this.state.action === "add") {
            button = (
                <Row className="centering">
                    <Col className="centering" sm={12}>
                        <Button className="mt-2" variant="primary" onClick={this.onShow}>Dodaj Nowy</Button>
                    </Col>
                </Row>
            )

        }
        else if (this.state.action === "edit") {
            button = (
                <Button className="mt-2" size="sm" variant="outline-primary" onClick={this.onShow}>Edytuj</Button>
            )
        }

        let images;
        if (this.state.photos.length > 0) {
            let list = this.state.photos.map(im => <p key={im.name}>&bull; {im.name}</p>)
            images = (
                <Form.Row>
                    <Col >
                        <Form.Label>Aktualne zdjęcia:</Form.Label>
                        {list}
                    </Col>
                </Form.Row>
            );
        }

        let uploadedImages;
        if (this.state.uploadedPhotos.length > 0) {
            let list = this.state.uploadedPhotos.map(im => <p key={im.name}>&bull; {im.name}</p>)
            uploadedImages = (
                <Form.Row>
                    <Col >
                        <Form.Label>Nowe zdjęcia:</Form.Label>
                        {list}
                    </Col>
                </Form.Row>
            );
        }

        return (
            <div>
                {button}

                <Modal show={this.state.modalShow} onHide={this.onCancel} size="lg" centered>
                    <Modal.Body>
                        <h5 className="centering">{this.state.action === "add"? "Dodaj" : "Modyfikuj"} Część Zbroi</h5>
                        <hr />

                        {this.state.ErrorMessage.length > 0 &&
                            <Row className="centering">
                                <Col sm="4">
                                    <Alert variant="danger">
                                        {this.state.ErrorMessage}
                                    </Alert>
                                </Col>
                            </Row>
                        }


                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Nazwa polska:</Form.Label>
                                    <Form.Control name="namePL" type="text" placeholder="Nazwa polska" value={this.state.namePL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Nazwa angielska:</Form.Label>
                                    <Form.Control name="nameEN" type="text" placeholder="Nazwa angielska" value={this.state.nameEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descPL" type="text" placeholder="Opis polski" value={this.state.descPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="3" name="descEN" type="text" placeholder="Opis angielski" value={this.state.descEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis polski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescPL" type="text" placeholder="Krótki opis polski" value={this.state.shortDescPL} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Krótki opis angielski:</Form.Label>
                                    <Form.Control as="textarea" rows="2" name="shortDescEN" type="text" placeholder="Krótki opis angielski" value={this.state.shortDescEN} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col>
                                    <Form.Label>Typ części:</Form.Label>
                                    <Form.Control as="select" name="typePL" value={this.state.typePL.length > 1 ? this.state.typePL : this.state.partTypes[0]} onChange={this.handleChange}>
                                        {typeSelectInner}
                                    </Form.Control>
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Czas produkcji (w godzinach):</Form.Label>
                                    <Form.Control name="hoursToMake" type="number" placeholder="Czas produkcji" value={this.state.hoursToMake} onChange={this.handleChange} />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Cena (EUR):</Form.Label>
                                    <Form.Control name="price" type="number" placeholder="Cena" value={this.state.price} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={4}>
                                    <Form.Label>Mobility score:</Form.Label>
                                    <Form.Control name="mobilityScore" type="number" min="0" max="5" placeholder="Mobility score" value={this.state.mobilityScore} onChange={this.handleChange} />
                                </Col>
                                <Col sm={4}>
                                    <Form.Label>Defence score:</Form.Label>
                                    <Form.Control name="defenceScore" type="number" min="0" max="5" placeholder="Defence score" value={this.state.defenceScore} onChange={this.handleChange} />
                                </Col>
                                <Col sm={4}>
                                    <Form.Label>Apperance score:</Form.Label>
                                    <Form.Control name="apperanceScore" type="number" min="0" max="5" placeholder="Apperance score" value={this.state.apperanceScore} onChange={this.handleChange} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={6}>
                                    <Form.Label>Część setów:</Form.Label>
                                    {armorSetsCheckboxes}
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Wymagane wymiary:</Form.Label>
                                    {measurementsCheckboxes}
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col >
                                    <Form.Label>Widoczność w sklepie:</Form.Label>
                                    <Form.Check
                                        id="available"
                                        type="checkbox"
                                        name="available"
                                        label="Dostępny"
                                        checked={this.state.isAvailable}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Form.Row>

                            {images}

                            <Form.Row>
                                <Col>
                                    <Form.Label>Wybierz nowe zdjęcia:</Form.Label><br />
                                    <input type="file" multiple onChange={this.handleImagesChange} />
                                </Col>
                            </Form.Row>

                            {uploadedImages}


                            <Form.Row>
                                <Button className="my-1 mx-1" size="sm" variant="primary" type="submit">Zatwierdź</Button><br />
                                <Button className="my-1 mx-1" size="sm" onClick={this.onCancel} variant="outline-danger">Anuluj</Button>
                            </Form.Row>

                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

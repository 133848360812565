import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

export class StatusNav extends Component {
    displayName = StatusNav.name
    constructor(props, context) {
        super(props, context);
        this.state = {
            key: props.tab,
        };
    }

    render() {
        return (
            <Tabs
                id="controlled-tab"
                className="mb-3 centering"
                activeKey={this.props.tab}
                onSelect={this.props.handleStatusChange}
            >
                <Tab eventKey="New" title="Nowe">
                </Tab>
                <Tab eventKey="Accepted" title="Zakceptowane">
                </Tab>
                <Tab eventKey="MoneyReceived" title="Opłacone">
                </Tab>
                <Tab eventKey="Ready" title="Gotowe do wysyłki">
                </Tab>
                <Tab eventKey="Removed" title="Usunięte">
                </Tab>
            </Tabs>
        );
    }
}

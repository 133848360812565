import React, { Component } from 'react';
import { Row, Col, Alert, Button, Modal } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { Rating } from './Rating';
import { Loading } from "../Loading/Loading";
import { FormattedMessage } from 'react-intl';
import CartActions from '../Cart/CartActions';




export class PartDetails extends Component {
    displayName = PartDetails.name;
    getPartUrl = "api/shop/get-part/"; // {partId}"

    constructor(props) {
        super(props);

        this.state = {

            lang: localStorage.lang,
            loading: true,
            errorMessage: "",
            data: {},

            partId: window.location.href.substr(window.location.href.lastIndexOf('/') + 1),
            modalShow: false,
        }
    };

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            fetch(this.getPartUrl + "/" + this.state.partId)
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        this.setState({ errorMessage: data.error, isLoading: false });
                    }
                    else {
                        this.setState({ data: data, isLoading: false });
                    }
                });
        });
    }

    getRating(score, id) {

        let inner = [];
        for (var i = 0; i < 5; i++) {
            if (i < score) {
                inner.push(<span key={`id${i}`} className="fa fa-star star-checked"></span>);
            }
            else {
                inner.push(<span key={`id${i}`} className="fa fa-star"></span>);
            }
        }
        return (
            <span className="ml-2">
                {inner}
            </span>);
    }
    onAddToCartClick = (event) => {
        let armorType = this.state.data.typeEN.toUpperCase();
        CartActions.AddToCart({
            type: armorType,
            id: this.state.data.id,
            count: 1
        });
        this.props.updateCartBadge();
        this.showGoToCartModal(event);
    }

    onModalHide = (event) => {
        this.setState({ modalShow: false });
    }
    showGoToCartModal = (event) => {
        this.setState({ modalShow: true });
    }
    onGoToCart = (event) => {
        this.props.history.push("/cart");
    }

    render() {
        if (this.state.errorMessage.length > 0) {
            return (<Row className="centering">
                <Col sm={4} >
                    <Alert variant="danger">
                        {this.state.errorMessage}
                    </Alert>
                </Col>
            </Row>);
        }

        if (this.state.isLoading) {
            return (<Loading />);
        }

        let galleryImages;
        let gallery;
        if (this.state.data.photos != null && this.state.data.photos.length > 0) {
            galleryImages = this.state.data.photos.map(p => { return { original: `app-images/${p}`, thumbnail: `app-images/${p}`} });
            gallery = <ImageGallery showPlayButton={false} items={galleryImages} />
        }

        return (
            <div>
                <Modal show={this.state.modalShow} onHide={this.onModalHide} size="sm" centered>
                    <Modal.Header className="centering">
                        <FormattedMessage id="h.itemAdded" />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="centering">
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" onClick={this.onModalHide} variant="outline-danger"><FormattedMessage id="h.continueShopping" /></Button>
                            </Col>
                            <Col className="centering">
                                <Button className="my-1 mx-1" size="sm" variant="danger" onClick={this.onGoToCart}><FormattedMessage id="h.goToCart" /></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Row className="centering mt-3 mx-auto part-details">
                    <Col sm="12" md="8">
                        {gallery}
                    </Col>

                    <Col md="4">
                        <Row className="align-me">
                            <Col>
                                <h1>{this.state.lang === "pl" ? this.state.data.namePL: this.state.data.nameEN}</h1>
                                <h2>{this.state.data.price} €</h2>
                            </Col>
                        </Row>

                        <Rating apperanceRating={this.state.data.apperanceScore} mobilityRating={this.state.data.mobilityScore} defenceRating={this.state.data.defenceScore} />

                        <Row className="mt-5">
                            <Col>
                                <Button variant="danger" onClick={this.onAddToCartClick}><FormattedMessage id="button.addToCart" /></Button>
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col>
                                <h6>{this.state.lang === "pl" ? this.state.data.shortDescPL : this.state.data.shortDescEN}</h6>
                            </Col>
                        </Row>

                    </Col>

                </Row>
                <Row className="centering my-5 mx-auto part-details">
                    <Col md={{ span: 6 }}>
                        {this.state.lang === "pl" ? this.state.data.descPL : this.state.data.descEN}
                    </Col>
                </Row>
            </div>

        );
    }
}